import { Text, VStack } from "@chakra-ui/layout";
import React, { FC } from "react";

type StatisticCardProps = {
  value: string | number;
  label: string;
  subLabel: string;
};
const StatisticCard: FC<StatisticCardProps> = ({ value, label, subLabel }) => {
  return (
    <VStack
      borderRadius="10px"
      padding="10px"
      minWidth="134px"
      maxWidth="134px"
      spacing={0}
      style={{
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(243, 243, 243, 0.20) 60%), #FFF",
        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.06)",
      }}
    >
      <Text
        fontSize="36px"
        fontFamily="Poppins"
        fontWeight={300}
        color="#C51836"
        m={0}
      >
        {value}
      </Text>
      <Text
        fontSize="13px"
        fontFamily="Oswald"
        fontWeight={500}
        color="#444145"
        m={0}
      >
        {label}
      </Text>
      <Text
        fontSize="9px"
        fontFamily="Poppins"
        fontWeight={400}
        color="#9B9A9C"
        m={0}
      >
        {subLabel}
      </Text>
    </VStack>
  );
};

export default StatisticCard;
