export const isValidPhone = (value: string | null | undefined) => {
  if (!value) {
    return false;
  }

  const isValidCharacters = /^[0-9+]+$/.test(value ?? "");
  if (!isValidCharacters) {
    return false;
  }

  if (value.startsWith("+61")) {
    return value.length === 13;
  }

  if (value.startsWith("04")) {
    return value.length === 10;
  }

  return false;
};
