import React, { useContext, useEffect, useMemo, useState } from "react";
import { createContext } from "react";
import { useParams } from "react-router";
import { useAdminApiClient } from "../hooks/useApiClient";

interface TVenueContext {
  venue: any;
}

const VenueContext = createContext<TVenueContext | null>(null);

export const VenueContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { venueId } = useParams();

  const apiClient = useAdminApiClient();
  const [venue, setVenue] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadVenue = async () => {
    setIsLoading(true);
    if (!venueId || venueId === "new") {
      setVenue(null);
      setIsLoading(false);
      return;
    }
    const singleVenue = await apiClient.getSingleVenue(
      venueId?.toString() ?? ""
    );
    setVenue(singleVenue.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!venueId) return;

    setIsLoading(true);
    loadVenue();
  }, [venueId]);

  const context = useMemo(
    () => ({
      isLoading,
      venue,
    }),
    [isLoading, venue]
  );

  return (
    <VenueContext.Provider value={context}>{children}</VenueContext.Provider>
  );
};

/**
 * Returns the business loaded from the `:businessId` path parameter
 */
export const useVenue = (): any => {
  const ctx = useContext(VenueContext);
  return ctx;
};
