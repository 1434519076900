import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBusiness } from "../../context/BusinessContext";
import { useProfile } from "../../context/ProfileContext";
import { useVenue } from "../../context/VenueContext";

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useProfile();
  const { firstName, lastName, isWorldAdmin } = profile;
  const business = useBusiness();
  const businessName = business?.srcBusiness?.name || " ";
  const { venue } = useVenue();

  const pathnames = location.pathname.split("/").filter((path) => path);

  const labelMap: { [key: string]: string } = {
    venues: "Venue",
    members: "Member",
    users: "User",
    reports: "Report",
    settings: "Setting",
    sessions: "Session",
    kyc: "KYC",
    member: "Member",
    otp: "OTP",
  };

  let breadcrumbItems = [
    {
      to: `/`,
      label: "Businesses",
    },
  ];

  //Home path
  if (pathnames.length === 1) {
    breadcrumbItems.push({
      to: `#`,
      label: labelMap[pathnames[pathnames.length - 1]],
    });
  }

  // Admin report path
  if (pathnames.length === 2) {
    breadcrumbItems.push({
      to: `/reports`,
      label: labelMap[pathnames[0]],
    });
    breadcrumbItems.push({
      to: `#`,
      label: labelMap[pathnames[pathnames.length - 1]],
    });
  }

  // Business page home path
  if (pathnames.length === 3) {
    if (!isWorldAdmin) {
      breadcrumbItems = [
        {
          to: `#`,
          label: `Welcome, ${firstName} ${lastName}`,
        },
      ];
    } else {
      breadcrumbItems.push({
        to: `/${pathnames.join("/")}`,
        label: businessName,
      });
    }
  }

  // 3rd level breadcrumb
  if (pathnames.length === 4) {
    breadcrumbItems.push({
      to: `/${pathnames.slice(0, 3).join("/")}`,
      label: businessName,
    });
    breadcrumbItems.push({
      to: `#`,
      label: labelMap[pathnames[pathnames.length - 1]],
    });
  }

  //4th level breadcrumb
  if (pathnames.length === 5) {
    if (pathnames.includes("venues")) {
      breadcrumbItems.push({
        to: `/${pathnames.slice(0, 3).join("/")}`,
        label: businessName,
      });
      breadcrumbItems.push({
        to: "#",
        label: venue?.title || "",
      });
    }

    if (pathnames.includes("members")) {
      breadcrumbItems.push({
        to: `/${pathnames.slice(0, 3).join("/")}`,
        label: businessName,
      });
      breadcrumbItems.push({
        to: `/${pathnames.slice(0, 4).join("/")}`,
        label: labelMap["members"],
      });
      breadcrumbItems.push({
        to: "#",
        label: "Detail",
      });
    }

    if (pathnames.includes("users")) {
      breadcrumbItems.push({
        to: `/${pathnames.slice(0, 3).join("/")}`,
        label: businessName,
      });
      breadcrumbItems.push({
        to: `/${pathnames.slice(0, 4).join("/")}`,
        label: labelMap["users"],
      });
      breadcrumbItems.push({
        to: "#",
        label: "Detail",
      });
    }

    if (pathnames.includes("reports")) {
      breadcrumbItems.push({
        to: `/${pathnames.slice(0, 3).join("/")}`,
        label: businessName,
      });
      breadcrumbItems.push({
        to: `/${pathnames.slice(0, 4).join("/")}`,
        label: labelMap["reports"],
      });
      breadcrumbItems.push({
        to: "#",
        label: labelMap[pathnames[pathnames.length - 1]],
      });
    }
  }

  return (
    <Breadcrumb
      separator=">"
      fontFamily="Poppins"
      fontSize="13px"
      fontWeight={400}
    >
      {pathnames.length == 0 && (
        <Text>
          Welcome, {firstName} {lastName}
        </Text>
      )}
      {pathnames.length > 0 &&
        breadcrumbItems.map((item, index) => {
          const isCurrentPage = index === breadcrumbItems.length - 1;
          return (
            <BreadcrumbItem key={item.to} isCurrentPage={true}>
              <BreadcrumbLink
                as="button"
                _focus={{ outline: "none", boxShadow: "none" }}
                _hover={{
                  textDecoration: isCurrentPage ? "none" : "underline",
                }}
                onClick={() => (isCurrentPage ? null : navigate(item.to))}
              >
                {item.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
