import { exportExcel } from "../../../util/xls";

export const exportMemberToExcel = async (payload: any) => {
  const data = payload.items.map((row: any) => {
    return {
      "First Name": row.firstName,
      "Last Name": row.lastName,
      "Badge Number": `${row.badgeNumber}`,
      "Connected Date": row.connectedDate,
      "Tapit Status": row.isTapIt,
      "Cardit+ Status": row.isCardIt,
    };
  });
  exportExcel(data, "Member", "Member report");
};
