import { Heading, VStack } from "@chakra-ui/layout";
import React from "react";
import { useLayoutContext } from "../../context/LayoutContext";

interface PageHeadingProps {
  children?: React.ReactNode;
  customStyle?: React.CSSProperties;
}

const Title = ({ children }: { children: React.ReactNode }) => {
  return (
    <Heading as="h1" width="100%" className="page-title">
      {children}
    </Heading>
  );
};

export const PageHeading = ({ children, customStyle }: PageHeadingProps) => {
  const { isDesktop } = useLayoutContext();

  const paddingProps = isDesktop
    ? { paddingLeft: "40px", paddingRight: "40px" }
    : { paddingLeft: "2", paddingRight: "2" };

  return (
    <VStack
      spacing="2"
      alignItems="start"
      justifyContent="center"
      height="116px"
      minWidth="1080px"
      bgGradient="linear(180deg, rgba(166, 163, 169, 0.74) 0%, rgba(166, 163, 169, 0.84) 100%)"
      {...paddingProps}
      style={{ ...customStyle }}
    >
      {children}
    </VStack>
  );
};

PageHeading.Title = Title;
