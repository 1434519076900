import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Link as ChakraLink,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NewBusinessModal } from "../../components/Business/NewBusinessModal";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { CopyText } from "../../components/Utilities/CopyText";
import { useBusiness } from "../../context/BusinessContext";
import { useProfile } from "../../context/ProfileContext";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useModalCloseEffect } from "../../hooks/useModalCloseEffect";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useToast } from "../../hooks/useToast";
import { PlusIcon } from "../../styles/icons";
import BusinessUtil from "../../util/BusinessUtil";
import { Result } from "../../util/result";
export const Users = () => {
  const { debouncedQuery, element: searchInputElement } = useSearchInput({
    placeholder: "Search Users...",
  });
  const newBusinessModal = useDisclosure();
  const contextBusiness = useBusiness();
  const profileContext = useProfile();
  const navigate = useNavigate();
  const apiClient = useAdminApiClient();
  const [records, setRecords] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();
  const { showSuccessToast, showErrorToast } = useToast();
  const [allowManageUser, setAllowManageUser] = useState(false);

  useEffect(() => {
    const isEligibleToManage = ["admin", "global"].includes(
      profileContext?.meta?.role?.toLowerCase()
    );
    if (isEligibleToManage) {
      setAllowManageUser(true);
    }
  }, [profileContext]);

  const refresh = () => {
    load();
  };

  const onDeleteUser = async (user: any) => {
    const { ok: isConfirmed }: { ok: boolean } = await showConfirmationDialog(
      "Delete User?",
      `Are you sure you wish to delete this user: ${user.firstName} ${user.lastName}?`,
      "Delete"
    );
    if (isConfirmed) {
      const result: Result<any> = await apiClient.deleteUserProfile(user._id);
      if (result.isSuccess) {
        refresh();
        showSuccessToast("User successfully deleted.");
      } else {
        showErrorToast("Failed to delete user profile");
      }
    }
  };

  // Refresh the business list when the modal is closed.
  useModalCloseEffect(newBusinessModal, () => refresh(), [refresh]);
  const load = async () => {
    const allBusinesses = await apiClient.getAllUsers(debouncedQuery);
    setRecords(allBusinesses.data.items);
    setIsLoading(false);
  };
  useEffect(() => {
    if (contextBusiness) {
      load();
    }
  }, [debouncedQuery, contextBusiness]);

  const skeletonLoadingTable = (rows: number = 12) => {
    return (
      <Tr>
        <Td width="15%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="75%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="10%" whiteSpace="nowrap">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
      </Tr>
    );
  };
  return (
    <>
      <PageHeading>
        <PageHeading.Title>Users</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%" overflowX="scroll">
          <VStack width="100%" spacing="4" alignItems="start">
            <HStack width="100%" justifyItems="start">
              <Box flex="1">
                <Box width="100%">{searchInputElement}</Box>
              </Box>
              <Button
                leftIcon={<PlusIcon />}
                onClick={() =>
                  navigate(BusinessUtil.getUrl(contextBusiness, "/users/new"))
                }
                colorScheme="cherryButton"
              >
                New User
              </Button>
            </HStack>
            <Table size="sm" className="primary-table">
              <Thead>
                <Tr>
                  <Th>Id</Th>
                  <Th>First Name</Th>
                  <Th>Last Name</Th>
                  <Th>Email</Th>
                  <Th>Role</Th>
                  {allowManageUser && <Th>Option</Th>}
                </Tr>
              </Thead>
              <Tbody>
                {(isLoading || !contextBusiness) && skeletonLoadingTable()}
                {!isLoading && records && records.length === 0 && (
                  <Tr>
                    <Td colSpan={4} textAlign="center">
                      No users found
                    </Td>
                  </Tr>
                )}
                {records &&
                  records.length > 0 &&
                  !isLoading &&
                  records?.map((record: any) => (
                    <Tr key={record._id}>
                      <Td title="Edit." w="10%">
                        <CopyText
                          text={record._id}
                          hover={true}
                          copyOnIcon={true}
                        >
                          <ChakraLink
                            as={Link}
                            to={BusinessUtil.getUrl(
                              contextBusiness,
                              `/users/${record._id}`
                            )}
                          >
                            {record._id}
                          </ChakraLink>
                        </CopyText>
                      </Td>
                      <Td>
                        <ChakraLink
                          as={Link}
                          to={BusinessUtil.getUrl(
                            contextBusiness,
                            `/users/${record._id}`
                          )}
                        >
                          {record.firstName}
                        </ChakraLink>
                      </Td>
                      <Td>
                        <ChakraLink
                          as={Link}
                          to={BusinessUtil.getUrl(
                            contextBusiness,
                            `/users/${record._id}`
                          )}
                        >
                          {record.lastName}
                        </ChakraLink>
                      </Td>
                      <Td>
                        <ChakraLink
                          as={Link}
                          to={BusinessUtil.getUrl(
                            contextBusiness,
                            `/users/${record._id}`
                          )}
                        >
                          {record.email}
                        </ChakraLink>
                      </Td>
                      <Td width="20%" whiteSpace="nowrap">
                        {record.meta.role}
                      </Td>
                      {allowManageUser && (
                        <Td width="20%" whiteSpace="nowrap">
                          <HStack spacing="24px">
                            <Tooltip label="Edit" placement="top">
                              <EditIcon
                                color="orange.400"
                                onClick={() =>
                                  navigate(
                                    BusinessUtil.getUrl(
                                      contextBusiness,
                                      `/users/update/${record._id}`
                                    )
                                  )
                                }
                              />
                            </Tooltip>
                            <Tooltip label="Delete" placement="top">
                              <DeleteIcon
                                color="red.500"
                                onClick={() => onDeleteUser(record)}
                              />
                            </Tooltip>
                          </HStack>
                        </Td>
                      )}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>An error was encountered while searching.</ErrorMessage>
        )}
        {confirmationElement}
      </PageContent>
      <NewBusinessModal {...newBusinessModal} />
    </>
  );
};
