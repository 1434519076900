import React, { useState } from "react";
import { Button, Input, Box, Text, useToast } from "@chakra-ui/react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useFirebase } from "../../context/FirebaseContext";
import { SaveButton } from "../Shared/SaveButton";

interface FileUploadProps {
  allowedWidth: number;
  allowedHeight: number;
  onComplete: Function;
}

const FileUpload = ({
  allowedWidth,
  allowedHeight,
  onComplete,
}: FileUploadProps) => {
  const { storage } = useFirebase();
  const [file, setFile] = useState<any>(null);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    // Check if file is an image
    if (!selectedFile.type.startsWith("image/")) {
      toast({
        title: "Invalid file type",
        description: "Please select an image file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Validate dimensions of the image
    const img = new Image();
    img.src = URL.createObjectURL(selectedFile);
    img.onload = () => {
      if (img.width === allowedWidth && img.height === allowedHeight) {
        setFile(selectedFile);
      } else {
        toast({
          title: "Invalid dimensions",
          description: `Image must be exactly ${allowedWidth}x${allowedHeight} pixels.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setFile(null); // Reset file if dimensions don't match
      }
      URL.revokeObjectURL(img.src); // Free up memory
    };
  };

  const handleUpload = () => {
    if (!file) {
      toast({
        title: "No file selected",
        description: "Please choose a file to upload.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsUploading(true);
    const filePath = `uploads/freeway/${file.name}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        setIsUploading(false);
      },
      () => {
        // Construct the permanent URL
        const bucketName = "cardit-plus.appspot.com"; // TODO: set as env
        const encodedFilePath = encodeURIComponent(filePath);
        const permanentUrl = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedFilePath}?alt=media`;

        setUrl(permanentUrl);
        onComplete(permanentUrl);
        setIsUploading(false);
        console.log("File available at", permanentUrl);

        // getDownloadURL(uploadTask.snapshot.ref)
        //   .then((downloadURL) => {
        //     setUrl(downloadURL);
        //     onComplete(downloadURL);
        //     setIsUploading(false);
        //     console.log("File available at", downloadURL);
        //   })
        //   .catch((e) => {
        //     setIsUploading(false);
        //   });
      }
    );
  };

  return (
    <Box py={2} textAlign="center" flexDir="row" display="flex" gap={5}>
      <Input type="file" onChange={handleFileChange} accept="image/*" />
      <SaveButton
        title={isUploading ? "Uploading..." : "Upload"}
        onClick={handleUpload}
        disable={!file || isUploading}
        isLoading={isUploading}
      ></SaveButton>
      {/* <Text mt={4}>Progress: {progress}%</Text> */}
      {/* {url && (
        <Text mt={4}>
          File uploaded!{" "}
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Text>
      )} */}
    </Box>
  );
};

export default FileUpload;
