import { exportExcel } from "./../../../util/xls";

export const exportKycToExcel = async (payload: any) => {
  const data = payload.items.map((row: any) => {
    return {
      "Member Name": row.memberName,
      "Venue Name": row.venueName,
      "Venue ID": row.venueId,
      "Badge Number": row.badgeNumber,
      Date: row.date,
      Time: row.time,
      Status: row.status,
    };
  });
  exportExcel(data, "KYC", "KYC report");
};
