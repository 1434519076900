import {
  Box,
  Button,
  Divider,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusiness } from "../../context/BusinessContext";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useToast } from "../../hooks/useToast";
import BusinessUtil from "../../util/BusinessUtil";

export const MemberDetail = () => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const contextBusiness = useBusiness();
  const { debouncedQuery: lookupText, element: lookupInputElement } =
    useSearchInput({
      placeholder: "Start typing...",
    });

  const { debouncedQuery: abnText, element: abnInputElement } = useSearchInput({
    placeholder: "Start typing...",
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const apiClient = useAdminApiClient();
  const [formData, setFormData] = useState<any>({
    modules: [],
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    // dummy
    // srcVenue: {
    //   Id: 34,
    //   Name: "Royal Venue 4",
    // },
  });
  const [businesses, setBusinesses] = useState<any>([]);
  const [abnRecords, setAbnRecords] = useState<any>([]);
  const [currentUser, setCurrentUser] = useState<any>();
  const [venueRecords, setVenueRecords] = useState<any>([]);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStepLoading, setIsStepLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { userId: originalUserId } = useParams();
  const userId: any = useMemo(() => {
    return originalUserId === "new" ? null : originalUserId;
  }, [originalUserId]);

  const specialKeys = [
    "addresses",
    "promoAccounts",
    "contactChannels",
    "customFields",
  ];

  const load = async (skipLoader = false) => {
    if (!skipLoader) {
      setIsLoading(true);
    }
    const singleVenue = await apiClient.getSingleMember(
      userId?.toString() ?? ""
    );
    setCurrentUser(singleVenue.data);
    setIsLoading(false);
    setIsSubmitted(false);
  };

  const areAllValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = formData.email && emailRegex.test(formData.email);
    return (
      formData.firstName && formData.lastName && formData.role && isEmailValid
    );
  };

  const onConfirm = (msg: string, callback: any) => {
    if (confirm(msg)) {
      callback();
    }
  };

  const updateForm = useCallback(
    (newData) => {
      setFormData({ ...formData, ...newData });
    },
    [formData]
  );

  const getLookupVenues = async () => {
    setIsStepLoading(true);
    if (!lookupText) {
      setVenueRecords([]);
      return;
    }
    const records = await apiClient.getLookupVenues(lookupText);
    setVenueRecords(records.data);
    setIsStepLoading(false);
  };

  const renderLoader = () => {
    return (
      isStepLoading && (
        <Box>
          <LoadingIndicator color="cherryUi.600" />
        </Box>
      )
    );
  };

  const unlinkMembership = () => {
    const ask =
      "Are you sure you want to unlink this membership from the member's account? The member will have to search and add their membership again.";
    onConfirm(ask, async () => {
      setIsSubmitted(true);
      const result = await apiClient.unlinkMember(userId);
      onCancel();
    });
  };

  const onCancel = () => {
    navigate(BusinessUtil.getUrl(contextBusiness, "/members"));
  };

  useEffect(() => {
    if (userId && contextBusiness) {
      load();
    }
  }, [userId, contextBusiness]);

  useEffect(() => {
    if (contextBusiness) {
      updateForm({
        role: contextBusiness?.isSystemRecord ? "Global" : "Staff",
      });
    }
  }, [contextBusiness]);

  useEffect(() => {
    if (userId) {
      if (currentUser) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [currentUser, userId]);

  if (isLoading || !contextBusiness) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <>
      <PageHeading>
        <PageHeading.Title>
          {currentUser
            ? currentUser.firstName + " " + currentUser.lastName
            : "New User"}
        </PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <Box>
            {userId && currentUser && !currentUser.isSystemRecord && (
              <HStack>
                <Box>
                  <Button
                    colorScheme="cherryButton"
                    type="submit"
                    isLoading={isSubmitted}
                    disabled={isStepLoading}
                    onClick={unlinkMembership}
                  >
                    Unlink Membership
                  </Button>
                </Box>

                <Box>
                  <Button disabled={isStepLoading} onClick={onCancel}>
                    Back
                  </Button>
                </Box>
              </HStack>
            )}
          </Box>
          <Box my="20px"></Box>

          <VStack width="100%" spacing="4" alignItems="start">
            {userId && currentUser && (
              <Box w="100%">
                <Table size="sm" className="primary-table">
                  <Thead>
                    <Tr>
                      <Th>Field</Th>
                      <Th>Value</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>venue</Td>
                      <Td>{currentUser.meta.populatedVenue.title}</Td>
                    </Tr>
                    {Object.keys(currentUser.externalMemberData).map((key) => (
                      <Tr key={key}>
                        <Td>{key}</Td>
                        <Td>
                          {specialKeys.indexOf(key) > -1 && (
                            <pre>
                              {JSON.stringify(
                                currentUser.externalMemberData[key],
                                null,
                                "  "
                              )}
                            </pre>
                          )}

                          {specialKeys.indexOf(key) === -1 &&
                            currentUser.externalMemberData[key].toString()}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                <Divider />
              </Box>
            )}
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>
            An error was encountered while searching businesses.
          </ErrorMessage>
        )}
      </PageContent>
    </>
  );
};
