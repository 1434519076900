import React, { useCallback, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { useFirebase } from "../../context/FirebaseContext";
import { Box, VStack, Text } from "@chakra-ui/layout";
import { Button, Image } from "@chakra-ui/react";

import ebetLogo from "assets/eBet_Logo.svg";
import loginBg from "assets/login-bkg.svg";
import { LoginForm } from "../../forms/LoginForm";
import { useToast } from "../../hooks/useToast";
import { PasswordResetForm } from "../../forms/PasswordResetForm";
import { useDataApiClient } from "../../hooks/useApiClient";
import axios, { Axios, AxiosResponse } from "axios";

interface LoginProps {
  onLoginSuccess?: (businessId?: string) => void;
}

const decodeJwt = (accessToken: string) => {
  try {
    return JSON.parse(window.atob(accessToken.split(".")[1]));
  } catch (e) {
    return {};
  }
};
// backgroundColor="cherryUi.600"

const AuthFormContainer = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <VStack
      width="100%"
      bg="#fff"
      borderRadius="8"
      shadow="md"
      maxWidth="400px"
      margin="0 auto"
      overflow="hidden"
    >
      <Text
        width="100%"
        textAlign="center"
        color="#fff"
        backgroundColor="#000"
        fontWeight="300"
        padding="4"
      >
        {title}
      </Text>
      <VStack width="100%" padding="4">
        {children}
      </VStack>
    </VStack>
  );
};

export const Login = ({ onLoginSuccess }: LoginProps) => {
  const { auth } = useFirebase();
  const { showErrorToast, showSuccessToast } = useToast();
  const [authForm, setAuthForm] = useState<"login" | "reset">("login");
  const [isReady, setIsReady] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);

  const onClickResetPassword = useCallback(
    () => setAuthForm("reset"),
    [setAuthForm]
  );

  const onCancelResetPassword = useCallback(
    () => setAuthForm("login"),
    [setAuthForm]
  );

  const onPasswordResetSuccess = useCallback(() => {
    showSuccessToast("Password reset email has beem sent.");
    setAuthForm("login");
  }, [setAuthForm, showSuccessToast]);

  const onSuccess = useCallback(
    (user: User) => {
      // Access the users decoded JWT so that we can read the businessId field.
      const decodedJwt = decodeJwt((user as any).accessToken);
      const isSuperAdmin = decodedJwt?.adminRole === "SuperAdmin";

      // if (!isSuperAdmin) {
      //   // auth.signOut();
      //   showErrorToast("You don't have permission to access this site");
      // }

      if (onLoginSuccess) {
        onLoginSuccess(decodedJwt.businessId);
      }
    },
    [onLoginSuccess]
  );

  const initiate = async () => {
    try {
      const result = await axios.get("https://api.ipify.org/?format=json");
      if (!result?.data?.ip) {
        setIsReady(true);
        setIsAccessDenied(true);
        return;
      }
      const preflightResult = await axios.post(
        `${API_BASE_URL}/shared/preflight`,
        {
          src: result.data.ip,
        }
      );
      console.log("preflightResult", preflightResult);
      setIsReady(true);
    } catch (e) {
      console.error(e);
      setIsReady(true);
      setIsAccessDenied(true);
    }
  };

  useEffect(() => {
    initiate();
  }, []);

  if (!isReady) {
    return (
      <Box position="relative">
        <VStack
          height="100vh"
          position="relative"
          align="center"
          spacing="8"
          paddingTop={20}
        >
          <Box
            marginBottom="20px"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image src={ebetLogo} alt="ebet Logo" w="120px" />
          </Box>
          <Text>Loading...</Text>
        </VStack>
      </Box>
    );
  }

  if (isAccessDenied) {
    return (
      <Box position="relative">
        <VStack
          height="100vh"
          position="relative"
          align="center"
          spacing="8"
          paddingTop={20}
        >
          <Box
            marginBottom="20px"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image src={ebetLogo} alt="ebet Logo" w="120px" />
          </Box>
          <Text>Access Denied</Text>
        </VStack>
      </Box>
    );
  }

  return (
    <>
      {/* <Image src={loginBg} position="absolute" minWidth="1200px" top="0" /> */}
      <Box position="relative">
        <VStack
          height="100vh"
          position="relative"
          align="center"
          spacing="8"
          paddingTop={20}
        >
          <Box
            marginBottom="20px"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image src={ebetLogo} alt="ebet Logo" w="120px" />
          </Box>
          {authForm === "login" && (
            <AuthFormContainer title="Sign in to your account">
              <LoginForm
                firebaseAuth={auth}
                onError={showErrorToast}
                onSuccess={onSuccess}
              />
              {/* <Button
                variant="ghost"
                fontWeight="400"
                onClick={onClickResetPassword}
              >
                I don't know my password
              </Button> */}
            </AuthFormContainer>
          )}
          {authForm === "reset" && (
            <AuthFormContainer title="Reset your password">
              <PasswordResetForm
                firebaseAuth={auth}
                onError={showErrorToast}
                onCancel={onCancelResetPassword}
                onSuccess={onPasswordResetSuccess}
              />
            </AuthFormContainer>
          )}
        </VStack>
      </Box>
    </>
  );
};
