import { Box, VStack, Text, HStack } from "@chakra-ui/react";
import React, { useState } from "react";
import ModalError from "../../../components/Modals/ModalError";
import { PageHeading } from "../../../components/PageHeading/PageHeading";
import ReportFilters from "../../../components/Report/ReportFilters";
import { useUserContext } from "../../../context/UserContext";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import DateUtil from "../../../util/DateUtil";
import { openPdf } from "../../../util/pdf";
import { exportOtpReportToExcel } from "./OtpExportToExcel";
import { OtpHtmlTemplate } from "./OtpHtmlTemplate";
import { useNavigate } from "react-router-dom";
import RoundedBackButton from "../../../components/ReoundedBackButton/RoundedBackButton";

const OtpReport = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const apiClient = useAdminApiClient();
  const [alert, setAlert] = useState({
    isOpen: false,
    subTitle: "",
  });

  const loadDataReport = async (params: any) => {
    const {
      startDate,
      endDate,
      selectedBusiness,
      selectedVenues = [],
    } = params;
    const venues = selectedVenues.map((venue: any) => venue.value);
    const venuesList = selectedVenues
      .map((venue: any) => venue.label)
      .join(", ");

    try {
      const res = await apiClient.getOtpReport({
        startDate,
        endDate,
        venues: venues.join(","),
      });
      const records: any = [];
      for (let item of res?.data?.items) {
        records.push({
          service: item.otpService,
          timestamp: DateUtil.getFormattedDate(
            new Date(item.createdAt?.replace("T", " ")?.replace("Z", "")),
            "ccc, dd/MM/yyyy HH:mm:ss"
          ),
          badgeNumber: item.badgeNo || "...",
          application: item.applicationName,
          createdAt: item.createdAt,
        });
      }
      if (records.length === 0) {
        setAlert({
          isOpen: true,
          subTitle: "No record available for selected period.",
        });
        return null;
      }
      const payload = {
        startDate: DateUtil.getFormattedDate(new Date(startDate), "dd/MM/yyyy"),
        endDate: DateUtil.getFormattedDate(new Date(endDate), "dd/MM/yyyy"),
        business: selectedBusiness.label,
        venues: venuesList,
        printed: DateUtil.getFormattedDate(new Date(), "dd/MM/yyyy HH:mm:ss"),
        user: user?.displayName || user?.email,
        items: records,
        total: records.length,
      };

      return payload;
    } catch (_err) {
      console.log("Failed generate member report", _err);
      setAlert({
        isOpen: true,
        subTitle: "Something went wrong, while generating the report.",
      });
      return null;
    }
  };

  const onGeneratePdf = async (params: any) => {
    setIsLoading(true);
    const payload = await loadDataReport(params);
    if (payload !== null) {
      const pdf = await apiClient.generatePdf(
        "otp-report",
        OtpHtmlTemplate,
        payload,
        "portrait"
      );
      openPdf(pdf);
    }
    setIsLoading(false);
  };

  const onGenerateExcel = async (params: any) => {
    setIsLoading(true);
    const payload = await loadDataReport(params);
    if (payload !== null) {
      await exportOtpReportToExcel(payload);
    }
    setIsLoading(false);
  };

  const currentDate = new Date();
  const minDate = currentDate.setMonth(currentDate.getMonth() - 3);

  return (
    <>
      <PageHeading>
        <HStack spacing={4}>
          <RoundedBackButton onClick={() => navigate(-1)} />
          <VStack alignItems="start">
            <Box>
              <PageHeading.Title>OTP REPORT</PageHeading.Title>
            </Box>
            <Text color="white" fontFamily="Poppins" fontSize="13px">
              Select a period and generate the report.
            </Text>
          </VStack>
        </HStack>
      </PageHeading>
      <Box paddingX="10" height="60%">
        <ReportFilters
          onGeneratePdf={onGeneratePdf}
          onGenerateExcel={onGenerateExcel}
          isLoading={isLoading}
          minDate={new Date(minDate)}
        />
        <ModalError
          isOpen={alert.isOpen}
          onClose={() =>
            setAlert({
              isOpen: false,
              subTitle: "",
            })
          }
          title="OTP report"
          subTitle={alert.subTitle}
        />
      </Box>
    </>
  );
};

export default OtpReport;
