import { Button } from "@chakra-ui/button";
import { Box, Spacer } from "@chakra-ui/layout";
import { Divider, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { FC, useEffect, useState } from "react";
import { useAdminApiClient } from "../../hooks/useApiClient";
import DatePicker from "../fields/DatePicker/DatePicker";
import DateUtil from "../../util/DateUtil";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ModalError from "../Modals/ModalError";

type ReportFiltersProps = {
  onGeneratePdf?: (params: any) => void;
  onGenerateExcel?: (params: any) => void;
  isLoading: boolean;
  maxDate?: Date;
  minDate?: Date;
};

export type SelectOption = {
  label: string;
  value: string;
  businessId?: string;
  _id?: string;
};

const ReportFilters: FC<ReportFiltersProps> = ({
  onGeneratePdf,
  onGenerateExcel,
  isLoading,
  maxDate = new Date(),
  minDate,
}) => {
  const apiClient = useAdminApiClient();
  const [businessOptions, setBusinessOptions] = useState<SelectOption[] | []>(
    []
  );
  const [venueOptions, setVenueOptions] = useState<SelectOption[] | []>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedBusiness, setSelectedBusiness] = useState<SelectOption>({
    label: "",
    value: "",
  });
  const [selectedVenues, setSelectedVenues] = useState<any>([]);
  const [alert, setAlert] = useState<{
    isOpen: boolean;
    errors: string[] | [];
  }>({
    isOpen: false,
    errors: [],
  });

  const compileBusinessOption = async () => {
    const businesses = await apiClient.getAllBusinesses();
    const businessList: SelectOption[] = [];
    businesses.data?.items?.forEach((item: any) => {
      if (item?.srcBusiness) {
        businessList.push({
          label: item.srcBusiness?.name,
          value: item.srcBusiness?.id,
          businessId: item?._id,
        });
      }
    });
    setBusinessOptions(businessList);
  };

  const compileVenueOption = async () => {
    const businessId = selectedBusiness?.businessId as string;
    localStorage.setItem("contextBusinessId", businessId);
    setTimeout(async () => {
      const venues = await apiClient.getAllVenues();
      const venueList: SelectOption[] = [];
      venues.data?.items?.forEach((item: any) => {
        if (item?.srcVenue) {
          venueList.push({
            label: item.srcVenue?.name,
            value: item.srcVenue?.id,
            _id: item._id,
          });
        }
      });
      setVenueOptions(venueList);
    }, 50);
  };

  const validateParams = () => {
    const errors = [];
    if (startDate === "") {
      errors.push("Start date is required!");
    }
    if (endDate === "") {
      errors.push("End date is required!");
    }
    if (new Date(startDate) > new Date(endDate)) {
      errors.push("Start date cannot be greater than end date!");
    }
    if (selectedBusiness.value === "") {
      errors.push("Business is required!");
    }
    if (selectedVenues.length === 0) {
      errors.push("Venue is required!");
    }
    if (errors.length > 0) {
      setAlert({
        isOpen: true,
        errors: errors,
      });
      return null;
    }

    return {
      startDate,
      endDate,
      selectedBusiness,
      selectedVenues,
    };
  };

  const handleGeneratePdf = () => {
    const params = validateParams();
    if (params !== null) {
      onGeneratePdf?.(params);
    }
  };

  const handleGenerateExcel = () => {
    const params = validateParams();
    if (params !== null) {
      onGenerateExcel?.(params);
    }
  };

  useEffect(() => {
    compileBusinessOption();
  }, []);

  useEffect(() => {
    if (!!selectedBusiness.value) {
      setSelectedVenues([]);
      compileVenueOption();
    }
  }, [selectedBusiness]);

  useEffect(() => {
    return () => localStorage.removeItem("contextBusinessId");
  }, []);

  return (
    <Box
      width={500}
      borderRadius={10}
      backgroundColor="cherryGrey.300"
      p={4}
      mt="15px !important"
      color="black"
    >
      <ModalError
        isOpen={alert.isOpen}
        onClose={() => setAlert({ isOpen: false, errors: [] })}
        title="Filters invalid"
        subTitle="Please resolve following error(s)"
        errors={alert.errors}
      />
      <Box>
        <Text fontSize={12} fontWeight="bold">
          REPORT PERIOD
        </Text>
        <Stack direction="row">
          <FormControl w="50%">
            <FormLabel fontSize={11}>Start Date:</FormLabel>
            <DatePicker
              onChange={(value) => setStartDate(value)}
              max={DateUtil.getFormattedDate(maxDate, "yyyy-MM-dd")}
              min={
                !!minDate
                  ? DateUtil.getFormattedDate(minDate, "yyyy-MM-dd")
                  : undefined
              }
            />
          </FormControl>
          <FormControl w="50%">
            <FormLabel fontSize={11}>End Date:</FormLabel>
            <DatePicker
              onChange={(value) => setEndDate(value)}
              max={DateUtil.getFormattedDate(maxDate, "yyyy-MM-dd")}
              min={
                !!minDate
                  ? DateUtil.getFormattedDate(minDate, "yyyy-MM-dd")
                  : undefined
              }
            />
          </FormControl>
        </Stack>
      </Box>

      <Spacer height={15} />
      <Divider borderColor="white" />
      <Spacer height={2} />

      <Box>
        <Text fontSize={12} fontWeight="bold">
          FILTER BY BUSINESS
        </Text>
        <FormControl w="100%" backgroundColor="white">
          <Select
            options={businessOptions}
            placeholder="Select options"
            closeMenuOnSelect={true}
            onChange={(selected: any) => setSelectedBusiness(selected)}
            size="sm"
            maxMenuHeight={120}
          />
        </FormControl>
      </Box>
      <Spacer height={15} />
      <Divider borderColor="white" />
      <Spacer height={2} />

      <Box>
        <Text fontSize={12} fontWeight="bold">
          FILTER BY VENUE
        </Text>
        <FormControl w="100%" backgroundColor="white">
          <Select
            isMulti
            value={selectedVenues}
            options={venueOptions}
            placeholder="Select options"
            closeMenuOnSelect={false}
            onChange={(selected: any) => setSelectedVenues(selected)}
            size="sm"
            maxMenuHeight={120}
          />
        </FormControl>
      </Box>

      <Spacer height={5} />
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          isLoading={isLoading}
          colorScheme="cherryButton"
          color="#fff"
          disabled={isLoading}
          px={5}
          fontSize={12}
          fontWeight="bold"
        >
          GENERATE
        </MenuButton>
        <MenuList>
          <MenuItem onClick={handleGeneratePdf}>PDF</MenuItem>
          <MenuItem onClick={handleGenerateExcel}>Excel</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default ReportFilters;
