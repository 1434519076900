import {
  Box,
  FormControl,
  HStack,
  Input,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import Geosuggest from "react-geosuggest";
import { useNavigate } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import RoundedBackButton from "../../components/ReoundedBackButton/RoundedBackButton";
import { CancelButton } from "../../components/Shared/CancelButton";
import { SaveButton } from "../../components/Shared/SaveButton";
import ToggleSwitch from "../../components/Shared/ToggleSwitch";
import { useBusiness } from "../../context/BusinessContext";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useToast } from "../../hooks/useToast";
import BusinessUtil from "../../util/BusinessUtil";
import { isValidEmail } from "../../util/isValidEmail";
import { isValidPhone } from "../../util/isValidPhone";
import { isEmptyStr } from "../../util/stringHelper";

export const AddVenue = () => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const contextBusiness = useBusiness();
  const { debouncedQuery: lookupText, element: lookupInputElement } =
    useSearchInput({
      placeholder: "Start typing...",
    });
  const apiClient = useAdminApiClient();
  const [formData, setFormData] = useState<any>({
    modules: [],
    transactionFee: 1,
  });
  const [validationError, setValidationError] = useState<string>("");
  const [venueRecords, setVenueRecords] = useState<any>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isStepLoading, setIsStepLoading] = useState<boolean>(false);

  const onSelectVenueRecord = async (record: any) => {
    updateForm({ srcVenue: record });
  };

  const onSuggestSelect = (suggest: any) => {
    if (!suggest) {
      return;
    }
    updateForm({
      address: suggest,
    });
  };

  const updateForm = useCallback(
    (newData) => {
      setFormData({ ...formData, ...newData });
    },
    [formData]
  );

  const getLookupVenues = async () => {
    setIsStepLoading(true);
    if (!lookupText) {
      setIsStepLoading(false);
      setVenueRecords([]);
      return;
    }
    const records = await apiClient.getLookupVenues(lookupText);
    setVenueRecords(records.data);
    setIsStepLoading(false);
  };

  const renderLoader = () => {
    return (
      isStepLoading && (
        <Box>
          <LoadingIndicator color="cherryUi.600" />
        </Box>
      )
    );
  };

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    let errorMessage = "";
    setValidationError(errorMessage);
    const isValidContactPhone = isValidPhone(formData.contactPhone);
    if (!isValidContactPhone) {
      errorMessage = "Please enter a valid phone number";
    }
    const isValidContactEmail = isValidEmail(formData.contactEmail);
    if (!isValidContactEmail) {
      errorMessage = "Please enter a valid email address";
    }
    if (isEmptyStr(formData.contactName) || formData.contactName.length < 2) {
      errorMessage = "Please enter a valid name";
    }
    if (formData.contactName && formData.contactName.length > 70) {
      errorMessage = "Name must not exceed 70 characters";
    }
    if (formData.isTransactionFeeEnabled && formData.transactionFee < 1) {
      errorMessage =
        "The minimum transaction fee required is $1. Please adjust your transaction amount accordingly";
    }

    if (errorMessage !== "") {
      setValidationError(errorMessage);
      setIsSubmitted(false);
      return;
    }

    try {
      const result = await apiClient.createVenue(formData);
      if (result.status >= 200 && result.status <= 299) {
        navigate(BusinessUtil.getUrl(contextBusiness, ""));
        showSuccessToast("Venue successfully enabled.");
      } else {
        let errorDesc = "";
        if (result.data.error === "exists") {
          errorDesc = "That venue already exists";
        }
        showErrorToast(errorDesc);
      }
    } catch (e) {
      showErrorToast("An unknown error occurred");
    }

    setIsSubmitted(false);
  }, [formData]);

  const onCancel = () => {
    navigate(BusinessUtil.getUrl(contextBusiness, ""));
  };

  const toggleModule = (moduleName: string) => {
    let modules: string[] = formData.modules || [];
    let kyb = formData.isKybChecked;
    if (modules.indexOf(moduleName) > -1) {
      modules = modules.filter((m) => m !== moduleName);
      if (moduleName === "LinkIT") {
        kyb = false;
      }
    } else {
      modules.push(moduleName);
    }
    updateForm({ modules, isKybChecked: kyb });
  };

  useEffect(() => {
    getLookupVenues();
  }, [lookupText]);

  const isCarditEnable = formData.modules.indexOf("LinkIT") > -1;
  const isTapitEnable = formData.modules.indexOf("TapIT") > -1;
  const isTransactionFeeEnable = formData.isTransactionFeeEnabled;

  return (
    <>
      <PageHeading customStyle={{ height: "116px" }}>
        <Box>
          <HStack spacing={6}>
            <Box>
              <RoundedBackButton onClick={onCancel} />
            </Box>
            <PageHeading.Title>Add Venue</PageHeading.Title>
          </HStack>
        </Box>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            {/* step 1: venue lookup */}
            {
              <Box>
                <Text as="h2" className="page-section-title" mb="14px">
                  STEP 1 - SELECT VENUE
                </Text>
                <Text className="page-section-content">
                  Find the venue you wish to enable from the ebet cloud:
                </Text>
                {formData.srcVenue && (
                  <Tooltip label="Edit">
                    <VStack
                      minWidth="400px"
                      alignItems="start"
                      padding="10px 15px"
                      backgroundColor="#FFF"
                      borderRadius="8px"
                      border="1px solid #DFDFE0"
                      boxShadow="0px 3px 6px -1px rgba(51, 44, 57, 0.06)"
                      mt="8px"
                      mb="40px"
                      spacing={0}
                      onClick={() => updateForm({ srcVenue: null })}
                    >
                      <Text
                        fontFamily="Poppins"
                        fontSize="12px"
                        color="#444145"
                        fontWeight="700"
                      >
                        {formData.srcVenue.name}
                      </Text>
                      <Text
                        fontFamily="Poppins"
                        fontSize="10px"
                        color="#9B9A9C"
                      >
                        ID:&nbsp;{formData.srcVenue.id}
                      </Text>
                    </VStack>
                  </Tooltip>
                )}
                {!formData.srcVenue && (
                  <Box>
                    <Box mt={2} flex="1">
                      <Box minWidth="400px">{lookupInputElement}</Box>
                    </Box>
                    {venueRecords && venueRecords.length > 0 && (
                      <Box
                        minWidth="400px"
                        backgroundColor="#DFDFE0"
                        borderRadius="8px"
                        padding="8px"
                        paddingBottom="4px"
                      >
                        {(venueRecords ?? []).map((record: any, i: number) => (
                          <VStack
                            key={`${record.Id}-${i}`}
                            w="100%"
                            spacing={0}
                            alignItems="start"
                            padding="10px"
                            backgroundColor="#FFF"
                            borderRadius="4px"
                            mb="4px"
                            as="button"
                            onClick={() => onSelectVenueRecord(record)}
                          >
                            <Text
                              fontFamily="Poppins"
                              fontSize="12px"
                              color="#444145"
                              fontWeight="700"
                            >
                              {record.name}
                            </Text>
                            <Text
                              fontFamily="Poppins"
                              fontSize="10px"
                              color="#9B9A9C"
                            >
                              ID:&nbsp;{record.id}
                            </Text>
                          </VStack>
                        ))}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            }

            {/* step 2: select address */}
            {formData.srcVenue && (
              <Box>
                <Text as="h2" className="page-section-title" mb="14px">
                  STEP 2 - SELECT ADDRESS
                </Text>
                <Text className="page-section-content">Select address:</Text>

                {!formData.address && (
                  <Box mt={2} mb={10} minWidth="400px">
                    <Geosuggest
                      placeholder="Enter address"
                      width="100%"
                      onSuggestSelect={onSuggestSelect}
                    />
                  </Box>
                )}

                {formData.address && (
                  <Tooltip label="Edit">
                    <VStack
                      minWidth="400px"
                      alignItems="start"
                      padding="10px 15px"
                      backgroundColor="#FFF"
                      borderRadius="8px"
                      border="1px solid #DFDFE0"
                      boxShadow="0px 3px 6px -1px rgba(51, 44, 57, 0.06)"
                      mt="8px"
                      mb="40px"
                      spacing={0}
                      onClick={() => updateForm({ address: null })}
                    >
                      <Text
                        fontFamily="Poppins"
                        fontSize="10px"
                        color="#9B9A9C"
                      >
                        {formData.address.gmaps?.formatted_address}
                      </Text>
                    </VStack>
                  </Tooltip>
                )}
              </Box>
            )}

            {/* step 3: contact details */}
            {formData.srcVenue && (
              <Box minWidth="400px" marginBottom="40px !important">
                <Text as="h2" className="page-section-title" mb="14px">
                  STEP 3 - CONTACT DETAILS
                </Text>
                <Text className="page-section-content">Name:</Text>
                <Input
                  mt={2}
                  bg="#fff"
                  w="100%"
                  type="text"
                  placeholder="Enter name"
                  onChange={(e) => updateForm({ contactName: e.target.value })}
                  value={formData.contactName}
                />

                <Text className="page-section-content" mt={4}>
                  Email Address:
                </Text>
                <Input
                  mt={2}
                  bg="#fff"
                  w="100%"
                  type="text"
                  placeholder="Enter email address"
                  onChange={(e) => updateForm({ contactEmail: e.target.value })}
                  value={formData.contactEmail}
                />

                <Text className="page-section-content" mt={4}>
                  Phone Number:
                </Text>
                <Input
                  mt={2}
                  bg="#fff"
                  w="100%"
                  type="text"
                  placeholder="Enter phone number"
                  onChange={(e) => updateForm({ contactPhone: e.target.value })}
                  value={formData.contactPhone}
                />
              </Box>
            )}

            {/* step 4: select modules */}
            {formData.srcVenue && (
              <Box minWidth="400px">
                <Text as="h2" className="page-section-title" mb="14px">
                  STEP 4 - SELECT MODULES
                </Text>
                <Text className="page-section-content">
                  Select modules to enable for this venue:
                </Text>
                <VStack width="241px" spacing="15px" alignItems="normal" mt={4}>
                  <ToggleSwitch
                    label="KYB Completed"
                    disabled={!isCarditEnable}
                    isChecked={formData.isKybChecked}
                    onChange={(e: any) =>
                      updateForm({ isKybChecked: e.target.checked })
                    }
                  />
                  <ToggleSwitch
                    label="cardit+ Enabled"
                    isChecked={isCarditEnable}
                    onChange={() => toggleModule("LinkIT")}
                  />
                  <ToggleSwitch
                    label="tapit Enabled"
                    isChecked={isTapitEnable}
                    onChange={() => toggleModule("TapIT")}
                  />
                  <ToggleSwitch
                    label="Transaction Fee ($)"
                    isChecked={isTransactionFeeEnable}
                    onChange={(e) =>
                      updateForm({ isTransactionFeeEnabled: e.target.checked })
                    }
                  />
                  <FormControl w="130px">
                    <Input
                      bg="#FFF"
                      boxShadow="0px 3px 6px -1px rgba(51, 44, 57, 0.06)"
                      w="100%"
                      type="number"
                      min={1}
                      onChange={(e) =>
                        updateForm({ transactionFee: e.target.value })
                      }
                      value={formData.transactionFee}
                      disabled={!isTransactionFeeEnable}
                    />
                  </FormControl>
                </VStack>
              </Box>
            )}

            {validationError && (
              <Box mt={4}>
                <ErrorMessage>{validationError}</ErrorMessage>
              </Box>
            )}

            {/* generic step loader */}
            {renderLoader()}
            <HStack spacing={4} marginTop="84px !important">
              <SaveButton
                isLoading={isSubmitted}
                disable={isSubmitted || !formData.address}
                onClick={onSubmit}
              />
              <CancelButton onClick={onCancel} />
            </HStack>
          </VStack>
        </Card>
      </PageContent>
    </>
  );
};
