import axios, { Axios } from "axios";
import { User } from "firebase/auth";
import { InvitationType } from "../enums/InvitationType";
import { Result } from "../util/result";
import { CherryHubAdminApi } from "./adminApiModels";
import { ApiClient, apiResult, ApiResult } from "./dataApiClient";

export class AdminApiClient implements ApiClient {
  adminApiClient: Axios;

  constructor(user: User) {
    this.adminApiClient = axios.create({
      baseURL: `${API_BASE_URL}${this.getPrefixRoute()}`,
      // HTTP errors will not throw exceptions
      validateStatus: () => true,
    });

    this.adminApiClient.interceptors.request.use((config) => {
      // Fetches the current access token. If it has expired or is close to exiring a new token will be fetched.
      return user.getIdTokenResult().then((idToken) => {
        if (!config.headers) {
          config.headers = {};
        }
        const contextBusinessId = localStorage.getItem("contextBusinessId");
        if (contextBusinessId) {
          config.headers["X-Business-Id"] = contextBusinessId ?? null;
        }
        config.headers!.Authorization = `Bearer ${idToken.token}`;
        return config;
      });
    });
  }

  getPrefixRoute(): string {
    // return "";
    return "";
  }

  /**
   *  Returns all Business documents
   */
  async getAllBusinesses(query?: string, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/businesses", {
      params: {
        q: query,
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async changePassword(newPassword: string): Promise<any> {
    const result = await this.adminApiClient.post(
      "/users/operations/change-password",
      { newPassword }
    );
    return result;
  }

  async getSingleBusiness(businessId: string): Promise<any> {
    const result = await this.adminApiClient.get(`/businesses/${businessId}`);
    return result;
  }

  async getLookupBusinesses(query?: string, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/businesses/lookup", {
      params: {
        q: query,
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async getAbnBusinesses(query?: string, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/businesses/abn", {
      params: {
        q: query,
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async enableBusiness(data?: any): Promise<any> {
    const result = await this.adminApiClient.post("/businesses", data);
    return result;
  }

  async modifyBusiness(businessId: string, data?: any): Promise<any> {
    const result = await this.adminApiClient.put(
      `/businesses/${businessId}`,
      data
    );
    return result;
  }

  // VENUES
  async getAllVenues(query?: string, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/venues", {
      params: {
        q: query,
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async getLookupVenues(query?: string, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/venues/lookup", {
      params: {
        q: query,
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async getSingleVenue(venueId: string): Promise<any> {
    const result = await this.adminApiClient.get(`/venues/${venueId}`);
    return result;
  }

  async createVenue(data?: any): Promise<any> {
    const result = await this.adminApiClient.post("/venues", data);
    return result;
  }

  async modifyVenue(recordId: string, data?: any): Promise<any> {
    const result = await this.adminApiClient.put(`/venues/${recordId}`, data);
    return result;
  }

  async getAllUsers(query?: string, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/users", {
      params: {
        q: query,
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async getSingleUser(userId: string): Promise<any> {
    const result = await this.adminApiClient.get(`/users/profile/${userId}`);
    return result;
  }

  async getAllMembers(query?: string, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/memberships", {
      params: {
        q: query,
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async getSingleMember(memberId: string): Promise<any> {
    const result = await this.adminApiClient.get(`/memberships/${memberId}`);
    return result;
  }

  async unlinkMember(memberId: string): Promise<any> {
    const result = await this.adminApiClient.delete(`/memberships/${memberId}`);
    return result;
  }

  async getMemberReport(query: {
    startDate: string;
    endDate: string;
    venues: string[];
  }): Promise<any> {
    const result = await this.adminApiClient.post(`/memberships/report`, query);
    return result;
  }

  async getProfile(): Promise<any> {
    const result = await this.adminApiClient.get(`/users/profile/own`);
    return result;
  }

  async createUser(data?: any): Promise<any> {
    const result = await this.adminApiClient.post("/users", data);
    return result;
  }

  async updateUserProfile(id: string, data: any): Promise<any> {
    try {
      const result = await this.adminApiClient.put(
        `/users/profile/${id}`,
        data
      );
      return new Result({ type: "success", data: result.data });
    } catch (error) {
      return new Result({ type: "fail", errors: error });
    }
  }

  async deleteUserProfile(id: string): Promise<any> {
    try {
      const result = await this.adminApiClient.delete(`/users/${id}`);
      return new Result({ type: "success", data: result.data });
    } catch (error) {
      return new Result({ type: "fail", errors: error });
    }
  }

  async toggleRole(userId?: any): Promise<any> {
    const result = await this.adminApiClient.post(
      `/users/${userId}/toggle-admin`
    );
    return result;
  }

  async getAllApikeys(query?: string, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/apikeys", {
      params: {
        q: query,
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async createApikey(data?: any): Promise<any> {
    const result = await this.adminApiClient.post("/apikeys", data);
    return result;
  }

  async getSingleApikey(venueId: string): Promise<any> {
    const result = await this.adminApiClient.get(`/apikeys/${venueId}`);
    return result;
  }

  async getAllSettings(query?: object, limit?: number): Promise<any> {
    const result = await this.adminApiClient.get("/settings", {
      params: {
        ...(query || {}),
        limit: limit ?? 500,
      },
    });
    return result;
  }

  async saveSetting(data?: any): Promise<any> {
    const result = await this.adminApiClient.post("/settings", data);
    return result;
  }

  /**
   *  Returns all Business documents
   */
  async getBusinesses(
    query?: string,
    limit?: number,
    continuationToken?: string
  ): Promise<
    ApiResult<CherryHubAdminApi.BusinessSummaryItemQueryResultSetResponse>
  > {
    const result = await this.adminApiClient.get("/businesses", {
      params: {
        q: query,
        limit: limit ?? 500,
        continuationToken,
      },
    });
    return apiResult<CherryHubAdminApi.BusinessSummaryItemQueryResultSetResponse>(
      result
    );
  }

  /**
   *  Looks up a Business through the abr.business.gov.au web service.
   */
  async lookupAbnRecord(
    method: string,
    query: string
  ): Promise<ApiResult<CherryHubAdminApi.ABNRecord[]>> {
    if (["abn", "acn", "name"].indexOf(method) === -1) {
      throw new Error(`Invalid ABN lookup type '${method}'`);
    }

    const result = await this.adminApiClient.get(`/business/lookup/${method}`, {
      params: { q: query },
    });

    return apiResult(result);
  }

  /**
   *  Creates a Business document.
   */
  async createBusiness(
    abnRecord: CherryHubAdminApi.ABNRecord
  ): Promise<ApiResult<CherryHubAdminApi.Business>> {
    const result = await this.adminApiClient.post("/business", abnRecord);

    return apiResult(result);
  }

  /**
   *  Update a Business document.
   */
  async updateBusiness(
    businessId: string,
    businessUpdate: Partial<CherryHubAdminApi.BusinessUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.Business>> {
    const result = await this.adminApiClient.patch(
      `/business/${businessId}`,
      businessUpdate
    );
    return apiResult(result);
  }

  /**
   * Returns a Business document by BusinessId.
   */
  async getBusiness(
    businessId: string
  ): Promise<ApiResult<CherryHubAdminApi.Business | null>> {
    const result = await this.adminApiClient.get(`/business/${businessId}`);

    return apiResult(result);
  }

  /**
   * Deletes a Business document by BusinessId. SysAdmin rights are required.
   */
  async deleteBusiness(businessId: string): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(`/business/${businessId}`);

    return apiResult(result);
  }

  /**
   *  Returns Venue documents by BusinessId.
   */
  async getBusinessVenuesResultSet(
    businessId: string,
    params: { q?: string; includeDeleted?: string; continuationToken?: string }
  ): Promise<
    ApiResult<CherryHubAdminApi.VenueSummaryItemQueryResultSetResponse>
  > {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/venues`,
      {
        params: {
          ...params,
          limit: 500,
        },
      }
    );

    return apiResult(result);
  }

  async getBusinessVenues(
    businessId: string,
    params: {
      q?: string;
      includeDeleted?: string;
      continuationToken?: string;
      limit?: number;
    }
  ): Promise<
    ApiResult<CherryHubAdminApi.VenueSummaryItemQueryResultSetResponse>
  > {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/venues`,
      {
        params: {
          ...params,
          limit: params.limit ?? 500,
        },
      }
    );

    return apiResult(result);
  }

  /**
   * Returns a Venue document by VenueId.
   */
  async getBusinessVenue(
    businessId: string,
    venueId: string
  ): Promise<ApiResult<CherryHubAdminApi.VenueDocument>> {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/venues/${venueId}`
    );

    return apiResult(result);
  }

  /**
   * Creates a Business Venue document.
   */
  async createBusinessVenue(
    businessId: string,
    venue: Partial<CherryHubAdminApi.VenueUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.VenueDocument>> {
    const result = await this.adminApiClient.post(
      `/business/${businessId}/venues`,
      venue
    );

    return apiResult(result);
  }

  /**
   * Updates an existing Venue document by VenueId.
   */
  async updateBusinessVenue(
    businessId: string,
    venueId: string,
    venueUpdates: Partial<CherryHubAdminApi.VenueUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.VenueDocument>> {
    const result = await this.adminApiClient.patch(
      `/business/${businessId}/venues/${venueId}`,
      venueUpdates
    );

    return apiResult(result);
  }

  /**
   * Deletes a Business's Venue document by VenueId.
   */
  async deleteBusinessVenue(
    businessId: string,
    venueId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/venues/${venueId}`
    );

    return apiResult(result);
  }

  async restoreBusinessVenue(
    businessId: string,
    venueId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/venues/${venueId}`,
      { params: { undelete: "true" } }
    );

    return apiResult(result);
  }

  /**
   * Returns list of CherryHub.DataPlatform.v1.CherryPassConfigurationSummaryItem by BusinessId and PassConfigurationType.
   */
  async getBusinessDigitalMembershipCardPassConfiguration(
    businessId: string,
    query?: string,
    limit?: number,
    continuationToken?: string
  ): Promise<
    ApiResult<CherryHubAdminApi.CherryPassConfigurationSummaryItemQueryResultSetResponse>
  > {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/pass-configuration-summaries`,
      {
        params: {
          passConfigurationType: "DigitalMembershipCard",
          q: query,
          limit: limit ?? 500,
          continuationToken,
        },
      }
    );

    return apiResult(result);
  }

  /**
   * Creates a Pass Configuration document.
   */
  async createPassConfiguration(
    businessId: string,
    config: Partial<CherryHubAdminApi.CherryPassConfigurationSummaryItem>
  ): Promise<ApiResult<CherryHubAdminApi.CherryPassConfigurationSummaryItem>> {
    const formData = new FormData();
    formData.append("passConfigurationType", config.ConfigurationType!);
    formData.append("displayName", config.DisplayName!);
    formData.append("passDescription", config.PassDescription!);
    const result = await this.adminApiClient.post(
      `/business/${businessId}/pass-configuration`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    return apiResult(result);
  }

  async getRedemptionFormConfigurations(
    businessId: string,
    params: { q?: string; includeDeleted?: string; continuationToken?: string }
  ): Promise<
    ApiResult<CherryHubAdminApi.RedemptionFormConfigurationResultSetResponse>
  > {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/redemption-form-configurations`,
      {
        params: {
          ...params,
          limit: 500,
        },
      }
    );

    return apiResult(result);
  }

  async getRedemptionFormConfiguration(
    businessId: string,
    redemptionFormConfigurationId: string
  ): Promise<ApiResult<CherryHubAdminApi.RedemptionFormConfigurationItem>> {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/redemption-form-configurations/${redemptionFormConfigurationId}`
    );
    return apiResult(result);
  }

  async editRedemptionFormConfiguration(
    businessId: string,
    redemptionFormConfigurationId: string,
    redemptionFormConfiguration: Partial<CherryHubAdminApi.RedemptionFormConfigurationUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.RedemptionFormConfigurationItem>> {
    const result = await this.adminApiClient.patch(
      `/business/${businessId}/redemption-form-configurations/${redemptionFormConfigurationId}`,
      redemptionFormConfiguration
    );
    return apiResult(result);
  }

  async addRedemptionFormConfiguration(
    businessId: string,
    redemptionFormConfiguration: Partial<CherryHubAdminApi.RedemptionFormConfigurationUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.RedemptionFormConfigurationItem>> {
    const result = await this.adminApiClient.post(
      `/business/${businessId}/redemption-form-configurations`,
      redemptionFormConfiguration
    );
    return apiResult(result);
  }

  async deleteRedemptionFormConfiguration(
    businessId: string,
    redemptionFormConfigurationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/redemption-form-configurations/${redemptionFormConfigurationId}`
    );
    return apiResult(result);
  }

  async restoreRedemptionFormConfiguration(
    businessId: string,
    redemptionFormConfigurationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/redemption-form-configurations/${redemptionFormConfigurationId}?undelete=true`
    );
    return apiResult(result);
  }

  /**
   * Returns a Business's Integrations.
   */
  async getBusinessIntegrations(
    businessId: string,
    params: {
      q?: string;
      includeDeleted?: string;
      limit?: number;
      continuationToken?: string;
    }
  ): Promise<
    ApiResult<CherryHubAdminApi.IntegrationSummaryItemQueryResultSetResponse>
  > {
    params.limit = params.limit ?? 500;
    const result = await this.adminApiClient.get(
      `/business/${businessId}/integrations`,
      {
        params: {
          ...params,
        },
      }
    );

    return apiResult(result);
  }

  /**
   * Returns an Integration document by IntegrationId.
   */
  async getBusinessIntegration(
    businessId: string,
    integrationId: string
  ): Promise<ApiResult<CherryHubAdminApi.Integration>> {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/integrations/${integrationId}`
    );

    return apiResult(result);
  }

  /**
   * Creates a Business Integration document.
   */
  async createBusinessIntegration(
    businessId: string,
    integration: CherryHubAdminApi.IntegrationUpdate
  ): Promise<ApiResult<CherryHubAdminApi.Integration>> {
    const result = await this.adminApiClient.post(
      `/business/${businessId}/integrations`,
      integration
    );

    return apiResult(result);
  }

  /**
   * Deletes a Business's Integration document by IntegrationId.
   */
  async deleteBusinessIntegration(
    businessId: string,
    integrationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/integrations/${integrationId}`
    );

    return apiResult(result);
  }

  async restoreBusinessIntegration(
    businessId: string,
    integrationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/integrations/${integrationId}`,
      { params: { undelete: "true" } }
    );

    return apiResult(result);
  }

  /**
   * Updates an existing Integration document by IntegrationId.
   */
  async updateBusinessIntegration(
    businessId: string,
    integrationId: string,
    integrationUpdate: Partial<CherryHubAdminApi.IntegrationUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.Integration>> {
    const result = await this.adminApiClient.patch(
      `/business/${businessId}/integrations/${integrationId}`,
      integrationUpdate
    );

    return apiResult(result);
  }

  /**
   * Returns an enum collection/values by type.
   */
  async getEnumConfigurations(
    enumType: string
  ): Promise<ApiResult<CherryHubAdminApi.EnumConfigurationItem[]>> {
    const result = await this.adminApiClient.get(
      `/configuration/enums/${enumType}`
    );

    return apiResult(result);
  }

  async updateIntegrationConfiguration(
    businessId: string,
    integrationId: string,
    configuration: string | null
  ): Promise<ApiResult<{ [key: string]: unknown }>> {
    const result = await this.adminApiClient.put(
      `/business/${businessId}/integrations/${integrationId}/configuration`,
      configuration,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return apiResult(result);
  }

  async updateIntegrationDataStore(
    businessId: string,
    integrationId: string,
    dataStore: string | null
  ): Promise<ApiResult<{ [key: string]: unknown }>> {
    const result = await this.adminApiClient.put(
      `/business/${businessId}/integrations/${integrationId}/data-store`,
      dataStore,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return apiResult(result);
  }

  /**
   * Returns a Business's On-Demand Membership Registration Form Configuration.
   */
  async getBusinessRegistrationFormConfigurations(
    businessId: string,
    params: {
      q?: string;
      includeDeleted?: string;
      limit?: number;
      continuationToken?: string;
    }
  ): Promise<
    ApiResult<CherryHubAdminApi.RegistrationFormConfigurationSummaryItemQueryResultSetResponse>
  > {
    params.limit = params.limit ?? 500;
    const result = await this.adminApiClient.get(
      `/business/${businessId}/registration-form-configurations`,
      {
        params: {
          ...params,
        },
      }
    );

    return apiResult(result);
  }

  /**
   * Creates a Business On-Demand Membership Registration Form Configuration document.
   */
  async createBusinessRegistrationFormConfiguration(
    businessId: string,
    registration: CherryHubAdminApi.RegistratinFormConfigurationUpdate
  ): Promise<ApiResult<CherryHubAdminApi.RegistrationFormConfiguration>> {
    const result = await this.adminApiClient.post(
      `/business/${businessId}/registration-form-configurations`,
      registration
    );

    return apiResult(result);
  }

  /**
   * Returns an On-Demand Membership Registration Form Configuration document by RegistrationFormConfigurationId.
   */
  async getBusinessRegistrationFormConfiguration(
    businessId: string,
    registrationId: string
  ): Promise<ApiResult<CherryHubAdminApi.RegistrationFormConfiguration>> {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/registration-form-configurations/${registrationId}`
    );

    return apiResult(result);
  }

  /**
   * Deletes a Business's On-Demand Membership Registration Form Configuration document by RegistrationFormConfigurationId.
   */
  async deleteBusinessRegistrationFormConfiguration(
    businessId: string,
    registrationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/registration-form-configurations/${registrationId}`
    );

    return apiResult(result);
  }

  async restoreBusinessRegistrationFormConfiguration(
    businessId: string,
    registrationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/registration-form-configurations/${registrationId}`,
      { params: { undelete: "true" } }
    );

    return apiResult(result);
  }

  /**
   * Updates an existing RegistrationFormConfiguration document by RegistrationFormConfigurationId.
   */
  async updateBusinessRegistrationFormConfiguration(
    businessId: string,
    registrationId: string,
    registrationUpdate: Partial<CherryHubAdminApi.RegistratinFormConfigurationUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.RegistrationFormConfiguration>> {
    const result = await this.adminApiClient.patch(
      `/business/${businessId}/registration-form-configurations/${registrationId}`,
      registrationUpdate
    );

    return apiResult(result);
  }
  async getMembershipRenewalFormConfigurations(
    businessId: string,
    params: { q?: string; includeDeleted?: string; continuationToken?: string }
  ): Promise<
    ApiResult<CherryHubAdminApi.MembershipRenewalFormConfigurationResultSetResponse>
  > {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/membership-renewal-form-configurations`,
      {
        params: {
          ...params,
          limit: 500,
        },
      }
    );

    return apiResult(result);
  }

  async getMembershipRenewalFormConfiguration(
    businessId: string,
    membershipRenewalFormConfigurationId: string
  ): Promise<
    ApiResult<CherryHubAdminApi.MembershipRenewalFormConfigurationItem>
  > {
    const result = await this.adminApiClient.get(
      `/business/${businessId}/membership-renewal-form-configurations/${membershipRenewalFormConfigurationId}`
    );
    return apiResult(result);
  }

  async addMembershipRenewalFormConfiguration(
    businessId: string,
    membershipRenewalData: Partial<CherryHubAdminApi.MembershipRenewalFormConfigurationUpdate>
  ): Promise<
    ApiResult<CherryHubAdminApi.MembershipRenewalFormConfigurationItem>
  > {
    const result = await this.adminApiClient.post(
      `/business/${businessId}/membership-renewal-form-configurations`,
      membershipRenewalData
    );
    return apiResult(result);
  }

  async editMembershipRenewalFormConfiguration(
    businessId: string,
    membershipRenewalId: string,
    membershipRenewalData: Partial<CherryHubAdminApi.MembershipRenewalFormConfigurationUpdate>
  ): Promise<
    ApiResult<CherryHubAdminApi.MembershipRenewalFormConfigurationItem>
  > {
    const result = await this.adminApiClient.patch(
      `/business/${businessId}/membership-renewal-form-configurations/${membershipRenewalId}`,
      membershipRenewalData
    );
    return apiResult(result);
  }

  async deleteMembershipRenewalFormConfiguration(
    businessId: string,
    membershipRenewalFormConfigurationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/membership-renewal-form-configurations/${membershipRenewalFormConfigurationId}`
    );
    return apiResult(result);
  }

  async restoreMembershipRenewalFormConfiguration(
    businessId: string,
    membershipRenewalFormConfigurationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/business/${businessId}/membership-renewal-form-configurations/${membershipRenewalFormConfigurationId}?undelete=true`
    );
    return apiResult(result);
  }

  /**
   * Returns Member's data by fieldName
   */
  async getMemberDataOptions(
    businessId: string,
    fieldName: string,
    venueId?: string
  ): Promise<ApiResult<CherryHubAdminApi.ScalarValue[]>> {
    const result = await this.adminApiClient.get(
      `/configuration/${businessId}/member-data/${fieldName}`,
      {
        params: {
          venueId: venueId,
        },
      }
    );
    return apiResult(result);
  }

  async generateNonComparativeReport(
    reportType:
      | "cherry-pay-card-status"
      | "cherry-pay-card-program-type"
      | "cherry-pay-card-funds-loaded"
      | "cherry-pass-downloaded"
      | "cherry-play/promotion-leaderboard-players"
      | "cherry-play/promotion-leaderboard"
      | "cherry-play/promotion-reward-detail"
      | "cherry-play/promotion-coupon-summary",
    startDate?: string | null,
    endDate?: string | null,
    businessIds?: string[] | null
  ): Promise<ApiResult<string>> {
    const result = await this.adminApiClient.get(`/reports/${reportType}`, {
      params: {
        startDate,
        endDate,
        businessIds: businessIds?.join(","),
      },
      responseType: "blob",
    });

    if (result.status < 300) {
      const url = URL.createObjectURL(result.data);
      const response: ApiResult<string> = {
        data: url,
        ok: true,
        statusCode: result.status,
      };
      return response;
    }
    return apiResult(result);
  }

  async generateComparativeReport(
    reportType:
      | "cherry-pay-card-status"
      | "cherry-pay-card-program-type"
      | "cherry-pay-card-funds-loaded"
      | "cherry-pass-downloaded"
      | "cherry-play/promotion-coupon-summary",
    period1StartDate?: string | null,
    period1EndDate?: string | null,
    period2StartDate?: string | null,
    period2EndDate?: string | null,
    businessIds?: string[] | null
  ): Promise<ApiResult<string>> {
    const result = await this.adminApiClient.get(
      `/reports/${reportType}/comparative-report`,
      {
        params: {
          period1StartDate,
          period1EndDate,
          period2StartDate,
          period2EndDate,
          businessIds: businessIds?.join(","),
        },
        responseType: "blob",
      }
    );

    if (result.status < 300) {
      const url = URL.createObjectURL(result.data);
      const response: ApiResult<string> = {
        data: url,
        ok: true,
        statusCode: result.status,
      };
      return response;
    }
    return apiResult(result);
  }

  async getCherryPassStatus(params: {
    businessIds?: string;
    invitationType?: InvitationType;
  }): Promise<ApiResult<CherryHubAdminApi.CherryPassStatusSummary[]>> {
    const result = await this.adminApiClient.get(
      `/reports/cherry-pass-status`,
      {
        params: {
          ...params,
        },
      }
    );

    return apiResult(result);
  }

  /**
   * Returns ODMA Url based on type
   */
  async getOdmaUrl(
    businessId: string,
    type: CherryHubAdminApi.OdmaUrlType,
    id: string
  ): Promise<ApiResult<CherryHubAdminApi.ScalarValue>> {
    const result = await this.adminApiClient.get(
      `/configuration/${businessId}/odma-url/${type}/${id}`
    );

    return apiResult(result);
  }

  /**
   * Returns Platform Applications.
   */
  async getPlatformApplications(params: {
    q?: string;
    limit?: number;
    continuationToken?: string;
    includeDeleted?: boolean;
  }): Promise<
    ApiResult<CherryHubAdminApi.PlatformApplicationSummaryItemQueryResultSetResponse>
  > {
    params.limit = params.limit ?? 500;
    const result = await this.adminApiClient.get(`/applications`, {
      params: {
        ...params,
      },
    });

    return apiResult(result);
  }

  /**
   * Returns an Application document by ApplicationId.
   */
  async getPlatformApplication(
    applicationId: string
  ): Promise<ApiResult<CherryHubAdminApi.PlatformApplication>> {
    const result = await this.adminApiClient.get(
      `/applications/${applicationId}`
    );

    return apiResult(result);
  }

  /**
   * Creates an Application document.
   */
  async createPlatformApplication(
    application: CherryHubAdminApi.PlatformApplicationUpdate
  ): Promise<ApiResult<CherryHubAdminApi.PlatformApplication>> {
    const result = await this.adminApiClient.post(`/applications`, application);

    return apiResult(result);
  }

  /**
   * Deletes an Application document by ApplicationId.
   */
  async deletePlatformApplication(
    applicationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/applications/${applicationId}`
    );

    return apiResult(result);
  }

  async restorePlatformApplication(
    applicationId: string
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(
      `/applications/${applicationId}`,
      { params: { undelete: "true" } }
    );

    return apiResult(result);
  }

  /**
   * Updates an existing Application document by ApplicationId.
   */
  async updatePlatformApplication(
    applicationId: string,
    applicationUpdate: Partial<CherryHubAdminApi.PlatformApplicationUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.PlatformApplication>> {
    const result = await this.adminApiClient.patch(
      `/applications/${applicationId}`,
      applicationUpdate
    );

    return apiResult(result);
  }

  /**
   * Returns Application's Permissions.
   */
  async getApplicationPermissions(): Promise<
    ApiResult<CherryHubAdminApi.ApplicationPermission[]>
  > {
    const result = await this.adminApiClient.get(
      `/configuration/application-permissions`
    );

    return apiResult(result);
  }

  /**
   * Gets Filtered Users from the Authentication database.
   */
  async getUsers(params: {
    uid?: string;
    businessId?: string;
    emailAddressContains?: string;
    phoneNumberContains?: string;
    displayNameContains?: string;
    customClaimKeys?: string;
    customClaimValueContains?: string;
    isDisabledEquals?: boolean;
    includeServiceAccount?: boolean;
    q?: string;
    limit?: number;
    continuationToken?: string;
  }): Promise<
    ApiResult<CherryHubAdminApi.UserAccountSummaryItemQueryResultSetResponse>
  > {
    params.limit = params.limit ?? 500;
    const result = await this.adminApiClient.get(`/auth/users`, {
      params: {
        ...params,
      },
    });

    return apiResult(result);
  }

  /**
   * Gets User By uId from the Authentication database.
   */
  async getUser(
    uid: string
  ): Promise<ApiResult<CherryHubAdminApi.UserAccount>> {
    const result = await this.adminApiClient.get(`/auth/users/${uid}`);

    return apiResult(result);
  }

  /**
   * Creates a User document.
   */
  async createUserOld(
    user: Partial<CherryHubAdminApi.NewUserAccount>
  ): Promise<ApiResult<CherryHubAdminApi.UserAccount>> {
    const result = await this.adminApiClient.post(`/auth/users`, user);

    return apiResult(result);
  }

  /**
   * Deletes a User document by UserId.
   */
  async deleteUser(
    uid: string,
    query: {
      id: string;
    }
  ): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.delete(`/auth/users/${uid}`, {
      params: {
        ...query,
      },
    });

    return apiResult(result);
  }

  /**
   * Updates an existing User by userId.
   */
  async updateUser(
    uid: string,
    userUpdate: Partial<CherryHubAdminApi.UserAccountUpdate>
  ): Promise<ApiResult<CherryHubAdminApi.UserAccount>> {
    const result = await this.adminApiClient.patch(
      `/auth/users/${uid}`,
      userUpdate
    );

    return apiResult(result);
  }

  /**
   * Enables a User in the Authentication database.
   */
  async enableUser(uid: string): Promise<ApiResult<boolean>> {
    const result = await this.adminApiClient.post(`/auth/users/${uid}/enable`);

    return apiResult(result);
  }

  /**
   * Disables a User in the Authentication database.
   */
  async disableUser(uid: string): Promise<ApiResult<boolean>> {
    const result = await this.adminApiClient.post(`/auth/users/${uid}/disable`);

    return apiResult(result);
  }

  async updateUserClaim(
    uid: string,
    claimName: string,
    claimValue: any
  ): Promise<ApiResult<CherryHubAdminApi.UserAccount>> {
    const result = await this.adminApiClient.put(
      `/auth/users/${uid}/claim/${claimName}`,
      claimValue,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    return apiResult(result);
  }

  async deleteUserClaim(
    uid: string,
    claimName: string
  ): Promise<ApiResult<CherryHubAdminApi.UserAccount>> {
    const result = await this.adminApiClient.delete(
      `/auth/users/${uid}/claim/${claimName}`
    );

    return apiResult(result);
  }

  async resetUserPassword(uid: string): Promise<ApiResult<any>> {
    const result = await this.adminApiClient.post(
      `/auth/users/${uid}/reset-password`
    );

    return apiResult(result);
  }

  async getMembers(params: {
    businessId?: string;
    q?: string;
    continuationToken?: string;
    limit?: number;
  }): Promise<
    ApiResult<CherryHubAdminApi.MemberSummaryItemQueryResultSetResponse>
  > {
    const result = await this.adminApiClient.get(`/business/members`, {
      params: {
        ...params,
        limit: 500,
      },
    });

    return apiResult(result);
  }

  async getMemberDetail(
    memberId: string,
    businessId?: string | undefined
  ): Promise<ApiResult<CherryHubAdminApi.Member>> {
    const result = await this.adminApiClient.get(
      `/business/members/${memberId}${
        businessId ? `?businessId=${businessId}` : ""
      }`
    );

    return apiResult(result);
  }

  async generatePdf(
    filename: string,
    html: string,
    records: any,
    orientation?: string,
    pageFormat?: string
  ): Promise<any> {
    const payload = {
      filename,
      html,
      data: JSON.stringify(records),
      pageFormat,
      orientation,
    };
    return await this.adminApiClient.post("/shared/pdf", payload, {
      responseType: "blob",
    });
  }

  async getOtpReport(query: {
    startDate: string;
    endDate: string;
    venues: string[];
  }): Promise<any> {
    const { startDate, endDate, venues } = query;

    return await this.adminApiClient.get(
      `/users/otp/report/json?startDate=${startDate}&endDate=${endDate}&venues=${venues}`
    );
  }

  async getMandateSummary(query: {
    startDate: string;
    businessId: string;
  }): Promise<Result<any>> {
    const { startDate, businessId } = query;
    try {
      const res = await this.adminApiClient.get(
        `/statistics/mandates?startDate=${startDate}&businessId=${businessId}`
      );
      return new Result({ type: "success", data: res.data.data });
    } catch (error) {
      return new Result({ type: "fail", errors: error });
    }
  }

  async getTransactionSummary(query: {
    startDate: string;
    businessId: string;
  }): Promise<Result<any>> {
    const { startDate, businessId } = query;
    try {
      const res = await this.adminApiClient.get(
        `/statistics/transactions?startDate=${startDate}&businessId=${businessId}`
      );
      return new Result({ type: "success", data: res.data.data });
    } catch (error) {
      return new Result({ type: "fail", errors: error });
    }
  }

  async getCardItEnableSummary(query: {
    startDate: string;
    business_id: string;
  }): Promise<Result<any>> {
    const { startDate, business_id } = query;
    try {
      const res = await this.adminApiClient.get(
        `/statistics/cardit-enable?startDate=${startDate}&business_id=${business_id}`
      );
      return new Result({ type: "success", data: res.data.data });
    } catch (error) {
      return new Result({ type: "fail", errors: error });
    }
  }
}
