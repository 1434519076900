import React, { useEffect, useState } from "react";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { PageContent } from "../../components/PageContent/PageContent";
import {
  Box,
  Button,
  HStack,
  SkeletonText,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react";
import { Card } from "../../components/Card/Card";
import { PlusIcon } from "../../styles/icons";
import { useModalCloseEffect } from "../../hooks/useModalCloseEffect";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { usePaginatedAdminApiRequest } from "../../hooks/usePaginatedApiRequest";
import { NewBusinessModal } from "../../components/Business/NewBusinessModal";
import { Link, useNavigate } from "react-router-dom";
import { CopyText } from "../../components/Utilities/CopyText";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useBusiness } from "../../context/BusinessContext";
import BusinessUtil from "../../util/BusinessUtil";
import DateUtil from "../../util/DateUtil";

export const Apikeys = () => {
  const { debouncedQuery, element: searchInputElement } = useSearchInput({
    placeholder: "Search...",
  });

  const newBusinessModal = useDisclosure();
  const contextBusiness = useBusiness();
  const navigate = useNavigate();
  const apiClient = useAdminApiClient();
  const [records, setRecords] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const refresh = () => {
    load();
  };

  // Refresh the business list when the modal is closed.
  useModalCloseEffect(newBusinessModal, () => refresh(), [refresh]);

  const load = async () => {
    const allBusinesses = await apiClient.getAllApikeys(debouncedQuery);
    setRecords(allBusinesses.data.items);
    setIsLoading(false);
  };

  useEffect(() => {
    if (contextBusiness) {
      load();
    }
  }, [debouncedQuery, contextBusiness]);

  const skeletonLoading = (rows: number = 12) => {
    return (
      <HStack width="100%" boxShadow="lg">
        <Box width="15%" padding="4" pr="0">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
        <Box width="75%" padding="4" pr="0">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
        <Box width="10%" pt="4" pb="4">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
      </HStack>
    );
  };

  const skeletonLoadingTable = (rows: number = 12) => {
    return (
      <Tr>
        <Td width="15%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="75%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="10%" whiteSpace="nowrap">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <PageHeading>
        <PageHeading.Title>API Keys</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            <HStack width="100%" justifyItems="start">
              <Box flex="1">
                <Box width="100%">{searchInputElement}</Box>
              </Box>
              <Button
                leftIcon={<PlusIcon />}
                onClick={() =>
                  navigate(BusinessUtil.getUrl(contextBusiness, "/apikeys/new"))
                }
                colorScheme="cherryButton"
              >
                New API Key
              </Button>
            </HStack>
            <Table size="sm" className="primary-table">
              <Thead>
                <Tr>
                  <Th>Title</Th>
                  <Th>Created On</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(isLoading || !contextBusiness) && skeletonLoadingTable()}
                {!isLoading && records && records.length === 0 && (
                  <Tr>
                    <Td colSpan={4} textAlign="center">
                      No keys found
                    </Td>
                  </Tr>
                )}
                {records &&
                  records.length > 0 &&
                  !isLoading &&
                  records?.map((record: any) => (
                    <Tr key={record._id}>
                      <Td>
                        <ChakraLink
                          as={Link}
                          to={BusinessUtil.getUrl(
                            contextBusiness,
                            `/apikeys/${record._id}`
                          )}
                        >
                          {record.title}
                        </ChakraLink>
                      </Td>
                      <Td width="20%" whiteSpace="nowrap">
                        {DateUtil.getLocalDateFormatFromString(
                          record.createdAt
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>An error was encountered while searching.</ErrorMessage>
        )}
      </PageContent>
      <NewBusinessModal {...newBusinessModal} />
    </>
  );
};
