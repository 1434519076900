import React, { useEffect, useState } from "react";
import { Box, FormControl, HStack, Input, Text } from "@chakra-ui/react";
import { CancelButton } from "../../../components/Shared/CancelButton";
import { SaveButton } from "../../../components/Shared/SaveButton";
import ToggleSwitch from "../../../components/Shared/ToggleSwitch";
import { useVenue } from "../../../context/VenueContext";
import { isEmptyStr } from "../../../util/stringHelper";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import { useToast } from "../../../hooks/useToast";

const VenueConfig = () => {
  const { venue } = useVenue();
  const apiClient = useAdminApiClient();
  const { showSuccessToast, showErrorToast } = useToast();

  const [form, setForm] = useState<any>({
    features: [{ customFields: false }],
    agreement: { arrangement: "" },
    routerFunction: "",
    host: "",
    apiVendorCode: "",
    apiDeviceCode: "",
    idpHost: "",
    paymentHost: "",
    idpClientId: "",
    paymentProvider: "",
  });
  const [formBuffer, setFormBuffer] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateForm = (data: any) => {
    setForm({ ...form, ...data });
  };

  const handleUseCustomField = (value: boolean) => {
    setForm({ ...form, features: [{ customFields: value }] });
  };

  const initializeForm = () => {
    updateForm({ ...form, ...venue.srcVenue });
    setFormBuffer({ ...form, ...venue.srcVenue });
  };

  const onCancel = () => {
    updateForm({ ...form, ...formBuffer });
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload = { srcVenue: { ...form } };
      await apiClient.modifyVenue(venue?._id, payload);
      setIsSubmitting(false);
      showSuccessToast("Venue successfully updated.");
      setFormBuffer({ ...form });
    } catch (e) {
      setIsSubmitting(false);
      showErrorToast("Failed to update the venue.");
      console.log(e);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const fields = [
      "routerFunction",
      "host",
      "apiVendorCode",
      "apiDeviceCode",
      "idpHost",
      "paymentHost",
      "idpClientId",
      "paymentProvider",
    ];
    fields.forEach((field) => {
      if (isEmptyStr(form[field])) {
        isValid = false;
      }
    });

    if (isEmptyStr(form?.agreement?.arrangement)) {
      isValid = false;
    }

    setIsFormValid(isValid);
  };

  useEffect(() => {
    initializeForm();
  }, [venue]);

  useEffect(() => {
    validateForm();
  }, [form]);

  const isUseCustomField = form.features.find(
    (x: any) => x.customFields
  )?.customFields;

  return (
    <>
      <Text as="h2" className="page-section-title" mb="30px">
        CONFIGURATIONS
      </Text>
      <Box maxWidth="600px">
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Router function:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) => updateForm({ routerFunction: e.target.value })}
            value={form.routerFunction}
          />
        </FormControl>
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Host:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) => updateForm({ host: e.target.value })}
            value={form.host}
          />
        </FormControl>
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Api vendor code:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) => updateForm({ apiVendorCode: e.target.value })}
            value={form.apiVendorCode}
          />
        </FormControl>
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Api device code:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) => updateForm({ apiDeviceCode: e.target.value })}
            value={form.apiDeviceCode}
          />
        </FormControl>
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">IDP host:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) => updateForm({ idpHost: e.target.value })}
            value={form.idpHost}
          />
        </FormControl>
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">IDP client ID:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) => updateForm({ idpClientId: e.target.value })}
            value={form.idpClientId}
          />
        </FormControl>
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Payment host:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) => updateForm({ paymentHost: e.target.value })}
            value={form.paymentHost}
          />
        </FormControl>
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Payment provider:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) => updateForm({ paymentProvider: e.target.value })}
            value={form.paymentProvider}
          />
        </FormControl>
        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Agreement:</Text>
          <Input
            w="100%"
            bg="#fff"
            type="text"
            onChange={(e) =>
              updateForm({ agreement: { arrangement: e.target.value } })
            }
            value={form?.agreement?.arrangement}
          />
        </FormControl>
        <Box width="241px">
          <ToggleSwitch
            label="Use custom field:"
            isChecked={isUseCustomField ?? false}
            onChange={(e) => {
              handleUseCustomField(e.target.checked);
            }}
          />
        </Box>
      </Box>

      <Box mt="60px">
        <HStack spacing={4}>
          <SaveButton
            isLoading={isSubmitting}
            disable={!isFormValid || isSubmitting}
            onClick={onSubmit}
          />
          <CancelButton onClick={onCancel} />
        </HStack>
      </Box>
    </>
  );
};

export default VenueConfig;
