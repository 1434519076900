export const switchTheme = {
  baseStyle: {
    track: {
      height: "16px",
      border: "2px solid #DFDFE0",
      bg: "white",
      _checked: {
        bg: "white",
      },
      _focus: {
        boxShadow: "none",
      },
    },
    thumb: {
      width: "16px",
      height: "16px",
      bg: "#C7C6C7",
      _checked: {
        bg: "#C51836",
      },
    },
  },
};
