import { Box, Text, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import ebetLogo from "assets/eBet_Logo.svg";
import { User } from "firebase/auth";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../../context/FirebaseContext";
import { useUserContext } from "../../context/UserContext";
import { PasswordChangeForm } from "../../forms/PasswordChangeForm";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useToast } from "../../hooks/useToast";

interface LoginProps {
  onChangeSuccess?: (businessId?: string) => void;
}

const decodeJwt = (accessToken: string) => {
  try {
    return JSON.parse(window.atob(accessToken.split(".")[1]));
  } catch (e) {
    return {};
  }
};
// backgroundColor="cherryUi.600"

const AuthFormContainer = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <VStack
      width="100%"
      bg="#fff"
      borderRadius="8"
      shadow="md"
      maxWidth="400px"
      margin="0 auto"
      overflow="hidden"
    >
      <Text
        width="100%"
        textAlign="center"
        color="#fff"
        backgroundColor="#000"
        fontWeight="300"
        padding="4"
      >
        {title}
      </Text>
      <VStack width="100%" padding="4">
        {children}
      </VStack>
    </VStack>
  );
};

export const ChangePassword = ({ onChangeSuccess }: LoginProps) => {
  const { auth } = useFirebase();
  const { logout } = useUserContext();
  const { showErrorToast, showSuccessToast } = useToast();
  const apiClient = useAdminApiClient();
  const navigate = useNavigate();

  const [authForm, setAuthForm] = useState<"login" | "reset">("reset");

  const onClickResetPassword = useCallback(
    () => setAuthForm("reset"),
    [setAuthForm]
  );

  const onCancelResetPassword = useCallback(
    () => setAuthForm("login"),
    [setAuthForm]
  );

  const onPasswordResetSuccess = useCallback(() => {
    showSuccessToast(
      "Password has been changed successfully. Please login again with your new password"
    );
    logout().then(() => {
      navigate(`/`);
    });
  }, [setAuthForm, showSuccessToast]);

  const doChangePassword = async (newPassword: string) => {
    const response = await apiClient.changePassword(newPassword);
    return response?.data;
  };

  const onSuccess = useCallback(
    (user: User) => {
      // Access the users decoded JWT so that we can read the businessId field.
      const decodedJwt = decodeJwt((user as any).accessToken);
      const isSuperAdmin = decodedJwt?.adminRole === "SuperAdmin";

      // if (!isSuperAdmin) {
      //   // auth.signOut();
      //   showErrorToast("You don't have permission to access this site");
      // }

      if (onChangeSuccess) {
        onChangeSuccess(decodedJwt.businessId);
      }
    },
    [onChangeSuccess]
  );

  return (
    <>
      {/* <Image src={loginBg} position="absolute" minWidth="1200px" top="0" /> */}
      <Box position="relative">
        <VStack
          height="100vh"
          position="relative"
          align="center"
          spacing="8"
          paddingTop={20}
        >
          <Box
            borderRadius="10px"
            marginBottom="20px"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image src={ebetLogo} alt="ebet Logo" w="120px" />
          </Box>

          {authForm === "reset" && (
            <AuthFormContainer title="Change your password">
              <PasswordChangeForm
                doChangePassword={doChangePassword}
                firebaseAuth={auth}
                onError={showErrorToast}
                onCancel={onCancelResetPassword}
                onSuccess={onPasswordResetSuccess}
              />
            </AuthFormContainer>
          )}
        </VStack>
      </Box>
    </>
  );
};
