import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import ToggleSwitch from "../../../components/Shared/ToggleSwitch";
import { CancelButton } from "../../../components/Shared/CancelButton";
import { SaveButton } from "../../../components/Shared/SaveButton";
import { useVenue } from "../../../context/VenueContext";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import { useToast } from "../../../hooks/useToast";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";

const VenueSettingSection = () => {
  const apiClient = useAdminApiClient();
  const { showSuccessToast, showErrorToast } = useToast();
  const { venue } = useVenue();
  const [formData, setFormData] = useState<any>({
    modules: [],
    transactionFee: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationError, setValidationError] = useState<string>("");

  const updateFormData = (newData: any) => {
    setFormData({ ...formData, ...newData });
  };

  const toggleModule = (moduleName: string) => {
    let modules: string[] = formData.modules || [];
    let kyb = formData.isKybChecked;
    if (modules.indexOf(moduleName) > -1) {
      modules = modules.filter((m) => m !== moduleName);
      if (moduleName === "LinkIT") {
        kyb = false;
      }
    } else {
      modules.push(moduleName);
    }
    updateFormData({ modules, isKybChecked: kyb });
  };

  const onCancel = () => {
    updateFormData({
      modules: venue?.modules ?? [],
      isTransactionFeeEnabled: !!venue?.isTransactionFeeEnabled,
      transactionFee: venue?.transactionFee ?? 0,
      isKybChecked: venue?.isKybChecked,
    });
    setValidationError("");
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (formData.isTransactionFeeEnabled && formData.transactionFee < 1) {
        setValidationError(
          "The minimum transaction fee required is $1. Please adjust your transaction amount accordingly"
        );
        setIsSubmitting(false);
        return;
      }

      await apiClient.modifyVenue(venue?._id, formData);
      setIsSubmitting(false);
      showSuccessToast("Venue successfully updated.");
    } catch (e) {
      setIsSubmitting(false);
      showErrorToast("Failed to update the venue.");
      console.log(e);
    }
  };

  useEffect(() => {
    if (!!venue) {
      updateFormData({
        modules: venue?.modules ?? [],
        isTransactionFeeEnabled: !!venue?.isTransactionFeeEnabled,
        transactionFee: venue?.transactionFee ?? 0,
        isKybChecked: venue?.isKybChecked,
      });
    }
  }, [venue]);

  const isCarditEnable = formData.modules.indexOf("LinkIT") > -1;
  const isTapitEnable = formData.modules.indexOf("TapIT") > -1;
  const isTransactionFeeEnable = formData.isTransactionFeeEnabled;

  return (
    <>
      <Text as="h2" className="page-section-title" mb="30px">
        CARDIT SETTINGS
      </Text>
      <VStack width="241px" spacing="15px" alignItems="normal">
        <ToggleSwitch
          label="KYB Completed"
          disabled={!isCarditEnable}
          isChecked={formData.isKybChecked}
          onChange={(e: any) =>
            updateFormData({ isKybChecked: e.target.checked })
          }
        />
        <ToggleSwitch
          label="cardit+ Enabled"
          isChecked={isCarditEnable}
          onChange={() => toggleModule("LinkIT")}
        />
        <ToggleSwitch
          label="tapit Enabled"
          isChecked={isTapitEnable}
          onChange={() => toggleModule("TapIT")}
        />
        <ToggleSwitch
          label="Transaction Fee ($)"
          isChecked={isTransactionFeeEnable}
          onChange={(e) =>
            updateFormData({ isTransactionFeeEnabled: e.target.checked })
          }
        />
        <FormControl w="130px">
          <Input
            bg="#FFF"
            boxShadow="0px 3px 6px -1px rgba(51, 44, 57, 0.06)"
            w="100%"
            type="number"
            min={0}
            onChange={(e) => updateFormData({ transactionFee: e.target.value })}
            value={formData.transactionFee}
            disabled={!isTransactionFeeEnable}
          />
        </FormControl>
      </VStack>
      {validationError && (
        <Box mt={4}>
          <ErrorMessage>{validationError}</ErrorMessage>
        </Box>
      )}
      <Box mt="60px">
        <HStack spacing={4}>
          <SaveButton
            isLoading={isSubmitting}
            disable={isSubmitting}
            onClick={onSubmit}
          />
          <CancelButton onClick={onCancel} />
        </HStack>
      </Box>
    </>
  );
};

export default VenueSettingSection;
