import { exportExcel } from "../../../util/xls";

export const exportOtpReportToExcel = async (payload: any) => {
  const data = payload.items.map((row: any) => {
    return {
      "Service Used": row.service,
      Timestamp: row.timestamp,
      "Badge Number": `${row.badgeNumber}`,
      Application: row.application,
    };
  });

  exportExcel(data, "OTP", "OTP report");
};
