import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Chrome, Colorful } from "@uiw/react-color";

import {
  Box,
  Button,
  Divider,
  FormControl,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
} from "@chakra-ui/react";
import { CancelButton } from "../../../components/Shared/CancelButton";
import { SaveButton } from "../../../components/Shared/SaveButton";
import ToggleSwitch from "../../../components/Shared/ToggleSwitch";
import { useVenue } from "../../../context/VenueContext";
import { isEmptyStr } from "../../../util/stringHelper";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import { useToast } from "../../../hooks/useToast";
import FileUpload from "../../../components/FileUpload/FileUpload";

const FreewayTheme = () => {
  const { venue } = useVenue();
  const apiClient = useAdminApiClient();
  const { showSuccessToast, showErrorToast } = useToast();

  const [form, setForm] = useState<any>({
    is_light_mode: false,
    primary_color: "#c51836",
    primary_color_bg1: "#ffffff",
    primary_color_bg2: "rgba(240,240,240)",
    container_color: "#ffffff",
    container_color_bg: "rgba(0,0,0,0.8)",
    container_color_bg2: "rgba(12,10,12,0.7)",
    font_1: "Oswald",
    font_2: "Poppins",
  });
  const [formBuffer, setFormBuffer] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryString, setQueryString] = useState("");
  const intervalRef = useRef<any>();

  const updateQueryString = useCallback(() => {
    const str = Object.keys(form).map((key) => {
      const val = encodeURIComponent(form[key]);
      return `${key}=${val}`;
    });
    setQueryString(str.join("&"));
  }, [form]);

  useEffect(() => {
    clearTimeout(intervalRef.current);
    intervalRef.current = setTimeout(() => {
      updateQueryString();
    }, 2000);
  }, [form]);

  const updateForm = (data: any) => {
    setForm({ ...form, ...data });
  };

  const toggleTheme = useCallback(() => {
    let newFormData = {};
    if (form.is_light_mode) {
      newFormData = {
        is_light_mode: false,
        container_color: "#ffffff",
        container_color_bg: "rgba(0,0,0,0.8)",
        container_color_bg2: "rgba(12,10,12,0.7)",
      };
    } else {
      newFormData = {
        is_light_mode: true,
        container_color: "#000000",
        container_color_bg: "rgba(255,255,255,0.8)",
        container_color_bg2: "rgba(245,255,245,0.7)",
      };
    }
    setForm({ ...form, ...newFormData });
  }, [form]);

  const handleUseCustomField = (value: boolean) => {
    setForm({ ...form, features: [{ customFields: value }] });
  };

  const initializeForm = () => {
    updateForm({ ...form, ...venue.freewayTheme });
    setFormBuffer({ ...form, ...venue.freewayTheme });
  };

  const onCancel = () => {
    updateForm({ ...form, ...formBuffer });
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload = { freewayTheme: { ...form } };
      await apiClient.modifyVenue(venue?._id, payload);
      setIsSubmitting(false);
      showSuccessToast("Theme successfully updated.");
      setFormBuffer({ ...form });
    } catch (e) {
      setIsSubmitting(false);
      showErrorToast("Failed to update the Theme.");
      console.log(e);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const fields = ["primary_color"];
    fields.forEach((field) => {
      if (isEmptyStr(form[field])) {
        isValid = false;
      }
    });

    setIsFormValid(isValid);
  };

  useEffect(() => {
    initializeForm();
  }, [venue]);

  useEffect(() => {
    validateForm();
  }, [form]);

  return (
    <>
      <Text as="h2" className="page-section-title" mb="30px">
        FREEWAY&nbsp;THEME
      </Text>
      <Box maxWidth="600px">
        {/* primary color */}
        <Box my={4}>
          <Text className="page-section-content">
            Column 1: Primary Color
            <br />
            <small>Applies to the background and button text.</small>
          </Text>
          <Popover>
            <PopoverTrigger>
              <Button
                aria-label={form.primary_color}
                background={form.primary_color}
                _hover={{ background: form.primary_color }}
                height="22px"
                width="82px"
                border="1px solid #555"
                padding={0}
                minWidth="unset"
                borderRadius={10}
              ></Button>
            </PopoverTrigger>
            <PopoverContent outline="none" width="270px">
              <PopoverArrow bg={form.primary_color} />
              <PopoverCloseButton color="#000" />
              <Chrome
                showAlpha={false}
                color={form.primary_color}
                onChange={(color) => updateForm({ primary_color: color.hex })}
              />
            </PopoverContent>
          </Popover>
        </Box>

        {/* secondary color */}
        <Box my={4}>
          <Text className="page-section-content">
            Column 1: Secondary Color
            <br />
            <small>
              Applies to the button background, TAP TO START text, and
              DISCONNECT text.
            </small>
          </Text>
          <Popover>
            <PopoverTrigger>
              <Button
                aria-label={form.primary_color_bg1}
                background={form.primary_color_bg1}
                _hover={{ background: form.primary_color_bg1 }}
                height="22px"
                width="82px"
                border="1px solid #555"
                padding={0}
                minWidth="unset"
                borderRadius={10}
              ></Button>
            </PopoverTrigger>
            <PopoverContent outline="none" width="270px">
              <PopoverArrow bg={form.primary_color_bg1} />
              <PopoverCloseButton color="#000" />
              <Chrome
                showAlpha={false}
                color={form.primary_color_bg1}
                onChange={(color) =>
                  updateForm({ primary_color_bg1: color.hex })
                }
              />
            </PopoverContent>
          </Popover>
        </Box>

        {/* secondary color */}
        <Box my={4}>
          <Text className="page-section-content">
            Column 1: Secondary Color 2<br />
            <small>
              Applies to the gradient effect on the buttons. Keep this color
              close to the Secondary Color.
            </small>
          </Text>
          <Popover>
            <PopoverTrigger>
              <Button
                aria-label={form.primary_color_bg2}
                background={form.primary_color_bg2}
                _hover={{ background: form.primary_color_bg2 }}
                height="22px"
                width="82px"
                border="1px solid #555"
                padding={0}
                minWidth="unset"
                borderRadius={10}
              ></Button>
            </PopoverTrigger>
            <PopoverContent outline="none" width="270px">
              <PopoverArrow bg={form.primary_color_bg2} />
              <PopoverCloseButton color="#000" />
              <Chrome
                showAlpha={false}
                color={form.primary_color_bg2}
                onChange={(color) =>
                  updateForm({ primary_color_bg2: color.hex })
                }
              />
            </PopoverContent>
          </Popover>
        </Box>

        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Primary Font:</Text>
          <Select
            value={form.font_1}
            onChange={(e) => updateForm({ font_1: e.target.value })}
          >
            <option value="Oswald">Oswald</option>
            <option value="Poppins">Poppins</option>
          </Select>
        </FormControl>

        <FormControl w="100%" mb={4}>
          <Text className="page-section-content">Secondary Font:</Text>
          <Select
            value={form.font_2}
            onChange={(e) => updateForm({ font_2: e.target.value })}
          >
            <option value="Oswald">Oswald</option>
            <option value="Poppins">Poppins</option>
          </Select>
        </FormControl>

        <FormControl w="100%" mb={4}>
          <Text mb={2} className="page-section-content">
            Background Image (1920x480px only):
          </Text>
          <FileUpload
            allowedHeight={480}
            allowedWidth={1920}
            onComplete={(url: string) => {
              updateForm({ bgUrl: encodeURIComponent(url) });
            }}
          />
        </FormControl>

        <Box width="151px">
          <ToggleSwitch
            label="Dark Mode:"
            isChecked={!form.is_light_mode}
            onChange={(e) => {
              toggleTheme();
            }}
          />
        </Box>
      </Box>
      <Divider mt={5} />

      <Text as="h3" mb="10px" mt="10px">
        Preview:{" "}
        <small style={{ color: "red" }}>
          (
          {queryString && (
            <a
              style={{ color: "red" }}
              target="_blank"
              href={FREEWAY_URL.concat(`?${queryString}`)}
            >
              new window
            </a>
          )}
          )
        </small>
      </Text>
      <Box
        style={{
          transform: "scale(0.5)",
          transformOrigin: "left top",
          height: "242px",
        }}
      >
        {queryString && (
          <iframe
            src={FREEWAY_URL.concat(`?${queryString}`)}
            width="1920px"
            height="480px"
          />
        )}
      </Box>

      <Box mt="60px">
        <HStack spacing={4}>
          <SaveButton
            isLoading={isSubmitting}
            disable={!isFormValid || isSubmitting}
            onClick={onSubmit}
          />
          <CancelButton onClick={onCancel} />
        </HStack>
      </Box>
    </>
  );
};

export default FreewayTheme;
