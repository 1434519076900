import React, { useState } from "react";
import { Tag, TagLabel, TagProps, TagRightIcon } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { useToast } from "../../hooks/useToast";

interface CopyTextProps {
  children: React.ReactNode;
  text?: string;
  successText?: string;
  hover?: boolean;
  adjustMargin?: boolean;
  copyOnIcon?: boolean;
}

export const CopyText = ({
  children,
  text,
  successText,
  hover,
  adjustMargin,
  copyOnIcon,
  ...props
}: CopyTextProps & TagProps) => {
  const [isHovering, setIsHovering] = useState<boolean>();
  const { showSuccessToast } = useToast();
  if (!text) text = children as string;

  return (
    <Tag
      cursor="pointer"
      onClick={() => {
        if (copyOnIcon === true) return;
        navigator.clipboard.writeText(text!);
        showSuccessToast(successText ?? "Copied to clipboard!");
      }}
      _hover={hover === true ? { backgroundColor: "gray.100" } : {}}
      backgroundColor={hover === true ? "transparent" : ""}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={
        adjustMargin === true
          ? { marginLeft: "-0.5rem", marginRight: "0.5rem" }
          : {}
      }
      {...props}
    >
      <TagLabel>{children}</TagLabel>
      <TagRightIcon
        display="inline-block"
        visibility={
          hover == true ? (isHovering ? "visible" : "hidden") : "hidden"
        }
        as={CopyIcon}
        onClick={() => {
          if (!copyOnIcon === true) return;
          navigator.clipboard.writeText(text!);
          showSuccessToast(successText ?? "Copied to clipboard!");
        }}
      />
    </Tag>
  );
};
